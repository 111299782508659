import invariant from "tiny-invariant";
import { isMatching, P } from "ts-pattern";

import remoteLog, { shouldLogError } from "~/common/logging";
import { API_TOKEN } from "~/config";
import type { AppDispatch } from "~/store";

const tokenPattern = {
  token: P.string,
};

export function getTokenAction(fields: P.infer<typeof tokenPattern>) {
  return { type: "GET_TOKEN_SUCCESS", fields } as const;
}

export function getToken() {
  return (dispatch: AppDispatch) => {
    fetch(API_TOKEN, { credentials: "include" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error occurred trying to fetch token");
        }
        return response;
      })
      .then((response) => response.json())
      .then((fields) => {
        invariant(isMatching(tokenPattern, fields), `${API_TOKEN} - unexpected json received`);
        dispatch(getTokenAction(fields));
      })
      .catch((err) => {
        if (shouldLogError(err)) {
          remoteLog(err, "getToken");
        }
      });
  };
}
