import React, { useEffect, useMemo, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage, useIntl } from "react-intl";
import { NavLink, Redirect, useHistory } from "react-router-dom";
import { useSpinDelay } from "spin-delay";
import invariant from "tiny-invariant";
import { isMatching, P } from "ts-pattern";

import { loginUser } from "~/actions/login";
import { addUserActionNotification } from "~/actions/user_notification";
import { deployedRussia, deployedUSA } from "~/common/utils";
import Loader from "~/components/common/loadingInProgress";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { API_LAST_NEWS } from "~/config";
import { useDocumentTitle } from "~/hooks/use-document-title";
import { useAppDispatch, useAppSelector } from "~/store";

import Logo from "./logo";
import Notification from "./notification";
import PageFooter from "./page_footer";
import { PREV_PATHNAME_STORAGE_KEY } from "./welcome";

const isNews = isMatching({
  text: P.string,
  title: P.string,
});

export default function Login() {
  const intl = useIntl();
  useDocumentTitle(intl.formatMessage({ id: "login.header" }));

  const history = useHistory();
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);
  const userIsLoading = useAppSelector((state) => state.userIsLoading);
  const banners = useAppSelector((state) => state.banners);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const displayLoading = useSpinDelay(isSubmitting, { delay: 0, minDuration: 300 });

  const loginRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const isMountedRef = useRef(false);

  const prevPathname = useMemo(() => {
    return typeof window == "undefined"
      ? null
      : window.localStorage.getItem(PREV_PATHNAME_STORAGE_KEY);
  }, []);

  useEffect(() => {
    // Focus the login field if user tried to visit a protected page before.
    if (prevPathname != null && prevPathname) {
      // Doesn't focus without a timeout 🤷
      setTimeout(() => loginRef.current?.focus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isMountedRef.current = true;

    if (deployedRussia() && intl.locale == "ru") {
      void getNews();
    }

    async function getNews() {
      try {
        const response = await fetch(API_LAST_NEWS);
        if (!response.ok) {
          throw new Error(`Error occurred trying to fetch news: ${response.statusText}`);
        }
        const json = await response.json();
        invariant(isNews(json), "unexpected json received for news");

        dispatch(
          addUserActionNotification({
            position: "bc",
            autoDismiss: 20,
            level: "info",
            message: json.text,
            title: json.title,
          }),
        );
      } catch {
        // ignore error
      }
    }

    return () => {
      isMountedRef.current = false;
      window.localStorage.removeItem(PREV_PATHNAME_STORAGE_KEY);
    };
  }, [dispatch, intl.locale]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    invariant(loginRef.current, "loginRef.current is undefined");
    invariant(passwordRef.current, "passwordRef.current is undefined");

    const loginValue = loginRef.current.value.trim();
    const passwordValue = passwordRef.current.value.trim();

    if (!loginValue) {
      loginRef.current.focus();
    } else if (!passwordValue) {
      passwordRef.current.focus();
    }

    if (!loginValue || !passwordValue) {
      return;
    }

    setIsSubmitting(true);
    setHasBeenSubmitted(true);

    try {
      await dispatch(loginUser(loginValue, passwordValue));
      const bannersHome = banners.filter((b) => b.align == "center");
      const nextPathname =
        prevPathname ||
        (!isMobile &&
        (deployedUSA() || (deployedRussia() && window.location.hostname.endsWith(".ru"))) &&
        bannersHome.length > 0
          ? "/pages/home"
          : "/pages/patients");
      history.push(nextPathname);
    } catch (err) {
      setError(err instanceof Error ? err.message : String(err));
      setTimeout(() => loginRef.current?.focus());
    } finally {
      if (isMountedRef.current) {
        setIsSubmitting(false);
      }
    }
  }

  if (userIsLoading) {
    return <Loader />;
  }

  if (!hasBeenSubmitted && Object.keys(user).length > 0) {
    return <Redirect to="/pages/patients" />;
  }

  return (
    <>
      <Notification />
      <Logo />

      <div className="content">
        <form className="login-form" method="post" onSubmit={handleSubmit}>
          <h3 className="form-title">
            <FormattedMessage id="login.header" />
          </h3>

          <ErrorAlert show={error != null} onClose={() => setError(null)} />

          <div className="form-group">
            {/* ie8, ie9 does not support html5 placeholder, so we just show field title for that */}
            <div className="input-icon">
              <i className="fa fa-user" />
              <label htmlFor="login-input" className="control-label visible-ie8 visible-ie9">
                <FormattedMessage id="login.login" />
              </label>

              <Input
                ref={loginRef}
                id="login-input"
                type="text"
                autoComplete="off"
                placeholder={intl.formatMessage({ id: "login.login" })}
                name="login"
                disabled={isSubmitting}
                className="tw-pl-8"
              />
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="password-input" className="control-label visible-ie8 visible-ie9">
              <FormattedMessage id="login.password" />
            </label>

            <div className="input-icon">
              <i className="fa fa-lock" />
              <Input
                ref={passwordRef}
                id="password-input"
                type={showPassword ? "text" : "password"}
                autoComplete="off"
                placeholder={intl.formatMessage({ id: "login.password" })}
                name="password"
                disabled={isSubmitting}
                className="tw-pl-8"
              />

              <i
                role="checkbox"
                tabIndex={0}
                aria-checked={showPassword}
                aria-label={intl.formatMessage({ id: "login.show_password" })}
                className="icon-eye"
                style={{
                  top: 10,
                  right: 10,
                  color: showPassword ? "black" : "grey",
                  margin: 0,
                }}
                onClick={() => setShowPassword((prevShowPassword) => !prevShowPassword)}
                onKeyDown={(event) => {
                  if (event.key == " ") {
                    setShowPassword((prevShowPassword) => !prevShowPassword);
                  }
                }}
              />
            </div>
          </div>

          <div className="form-actions">
            <label className="checkbox" style={{ visibility: "hidden" }}>
              <input type="checkbox" name="remember" defaultValue={1} />
            </label>

            <Button
              type="submit"
              className="pull-right"
              disabled={displayLoading}
              style={{ minWidth: 100 }}
            >
              <span role={displayLoading ? "status" : undefined}>
                <FormattedMessage id={displayLoading ? "loading" : "login.enter"} />
              </span>
            </Button>
          </div>

          <div className="forget-password">
            <p>
              <NavLink to="/recover/password">
                <FormattedMessage id="login.forgot_cta" />
              </NavLink>
            </p>
          </div>
        </form>
      </div>

      <div style={{ display: "flex", padding: 5, width: "100%" }}>
        <div
          style={{
            display: "block",
            margin: "0 auto",
            textAlign: "center",
            borderTop: "1px solid #c2cad8",
          }}
        >
          <PageFooter />
        </div>
      </div>
    </>
  );
}

function ErrorAlert({ show, onClose }: { show: boolean; onClose(): void }) {
  return (
    <div
      role="alert"
      className="alert alert-danger"
      style={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        transition: "all 300ms ease-in-out",
        opacity: show ? 1 : 0,
        height: show ? 52 : 0,
      }}
    >
      <FormattedMessage id="login.error" />

      <button
        className="close"
        style={{
          position: "absolute",
          top: 10,
          right: 8,
          transition: "color 250ms ease-in-out",
          visibility: show ? "visible" : "hidden",
        }}
        type="button"
        onClick={onClose}
      >
        <span className="sr-only">
          <FormattedMessage id="alert.close" />
        </span>
      </button>
    </div>
  );
}
