import remoteLog, { shouldLogError } from "~/common/logging";
import { API_EXTRA_SERVICES } from "~/config";
import type { TPatient } from "~/reducers/dashboard";
import type { AppDispatch } from "~/store";

export function clearExtraServices() {
  return { type: "CLEAR_EXTRA_SERVICES" } as const;
}

export function getExtraServices(patient_id: TPatient["patient_id"]) {
  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_EXTRA_SERVICES(patient_id), { credentials: "include" });
      if (!response.ok) {
        throw new Error("Failed loading {GET_EXTRA_SERVICES}: " + response.statusText);
      }
      const json = await response.json();
      dispatch({ type: "GET_EXTRA_SERVICES", json });
    } catch (err) {
      if (shouldLogError(err)) {
        remoteLog(err, "getExtraServices");
      }
    }
  };
}
