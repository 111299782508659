import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import { postBonusPatient } from "~/actions/doctor_bonuses";
import { endTreatment } from "~/actions/end_treatment";
import { isChildrenShortCourse } from "~/common/courses";
import { getLastCorrection } from "~/common/patient";
import { useUser } from "~/common/user";
import { PersonName } from "~/components/common/PersonName";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { Button } from "~/components/ui/button";
import { ConfirmationDialog } from "~/components/ui/dialog";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import type { TPatient } from "~/reducers/dashboard";
import { useAppDispatch } from "~/store";

export { PatientAdditionalServicesErrorBoundary as PatientAdditionalServices };

function PatientAdditionalServicesErrorBoundary(props: React.ComponentProps<typeof PatientAdditionalServices>) {
  return (
    <RemoteLogErrorBoundary component="patient_correction">
      <PatientAdditionalServices {...props} />
    </RemoteLogErrorBoundary>
  );
}

function PatientAdditionalServices({ patient }: { patient: TPatient }) {
  const {
    can_order_correction: canOrderCorrection,
    can_order_retainers: canOrderRetainers,
    can_end_treatment: canEndTreatment,
  } = getLastCorrection(patient).order_options;

  const canSetBonusPatient = patient.bonus_set_free;

  return (
    <Portlet>
      <PortletTitle as="h2" iconClassName="icon-speech">
        <FormattedMessage id="pat.block.right.additional" />
      </PortletTitle>

      <div className="portlet-body">
        <div className="row">
          <div className="col-md-4 tw-space-y-3.5">
            {canOrderCorrection ? <CorrectionButton patient={patient} /> : null}
            {canOrderRetainers ? <RetainersButton patient={patient} /> : null}
            {canEndTreatment ? <EndTreatmentButton patient={patient} /> : null}
          </div>

          <div className="col-md-3 col-md-offset-13">
            {canSetBonusPatient ? <BonusPatientButton patient={patient} /> : null}
          </div>
        </div>
      </div>
    </Portlet>
  );
}

function CorrectionButton({ patient }: { patient: TPatient }) {
  return (
    <Button asChild>
      <NavLink to={`/pages/patient/${patient.patient_id}/correct`}>
        <FormattedMessage
          id={
            isChildrenShortCourse(patient.course.course_id)
              ? "pat.status.button.additional_course"
              : "pat.status.button.correction"
          }
        />
      </NavLink>
    </Button>
  );
}

function RetainersButton({ patient }: { patient: TPatient }) {
  return (
    <Button asChild>
      <NavLink to={`/pages/patient/${patient.patient_id}/retainers`}>
        <FormattedMessage id="pat.status.button.retainers" />
      </NavLink>
    </Button>
  );
}

function EndTreatmentButton({ patient }: { patient: TPatient }) {
  const dispatch = useAppDispatch();

  function handleActionClick() {
    dispatch(endTreatment(patient.patient_id));
  }

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="pat.status.button.confirm" />}
      description={
        <>
          <FormattedMessage id="pat.status.button.end.description" />{" "}
          <PersonName as="strong" person={patient} />?
        </>
      }
      actionText={<FormattedMessage id="pat.status.button.end.confirm" />}
      onActionClick={handleActionClick}
    >
      <Button variant="secondary">
        <FormattedMessage id="pat.status.button.end" />
      </Button>
    </ConfirmationDialog>
  );
}

function BonusPatientButton({ patient }: { patient: TPatient }) {
  const dispatch = useAppDispatch();
  const user = useUser();

  function handleActionClick() {
    dispatch(postBonusPatient(user.account_id, patient.patient_id));
  }

  return (
    <ConfirmationDialog
      title={<FormattedMessage id="pat.status.button.confirm" />}
      description={
        <>
          <FormattedMessage id="bonus.patient.btn.description.1" />{" "}
          <PersonName as="strong" person={patient} />{" "}
          <FormattedMessage id="bonus.patient.btn.description.2" />?
        </>
      }
      actionText={<FormattedMessage id="bonus.patient.btn.confirm" />}
      onActionClick={handleActionClick}
    >
      <Button>
        <FormattedMessage id="bonus.patient.btn" />
      </Button>
    </ConfirmationDialog>
  );
}
