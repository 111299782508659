import invariant from "tiny-invariant";
import { isMatching, P } from "ts-pattern";

import type { TUser } from "~/reducers/login";
import { useAppSelector } from "~/store";

export function useUser(): TUser {
  const user = useAppSelector((state) => state.user);
  invariant(isUser(user), "expected user object to be in store");
  return user;
}

export function useOptionalUser(): TUser | null {
  const user = useAppSelector((state) => state.user);
  return isUser(user) ? user : null;
}

const userPattern = {
  account_id: P.number,
  account_type_id: P.number,
};

export function isUser(value: Partial<TUser>): value is TUser {
  return isMatching(userPattern, value);
}

export function canOrderTestPlastic(maybeUser: Partial<TUser>): boolean {
  return maybeUser.privileges?.test_plastic == 1;
}
