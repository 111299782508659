import { TPrescription } from "~/reducers/dashboard";
import { deployedUSA } from "./utils";

export const ARCH_BOTH = 1;
export const ARCH_UPPER = 2;
export const ARCH_LOWER = 3;

const UPPER_ARCH_MAINTAIN = 0;
const UPPER_ARCH_EXPAND = 1;
const UPPER_ARCH_NARROW = 2;
const UPPER_ARCH_NA = 3;

const LOWER_ARCH_MAINTAIN = 0;
const LOWER_ARCH_EXPAND = 1;
const LOWER_ARCH_NARROW = 2;
const LOWER_ARCH_NA = 3;

const TEETH_LEVEL_ALIGN_MARGIN = 1;
const TEETH_LEVEL_ALIGN_EDGE = 2;

const INCISORS_VERT_OVERLAP_MAINTAIN = 0;
const INCISORS_VERT_OVERLAP_DECREASE = 1;
const INCISORS_VERT_OVERLAP_INCREASE = 2;
const INCISORS_VERT_OVERLAP_NA = 3;

const INCISORS_UPPER_HORIZ_OVERLAP_MAINTAIN = 0;
const INCISORS_UPPER_HORIZ_OVERLAP_RETROCLINATE = 1;
const INCISORS_UPPER_HORIZ_OVERLAP_PROCLINATE = 2;
const INCISORS_UPPER_HORIZ_OVERLAP_NA = 3;

const INCISORS_LOWER_HORIZ_OVERLAP_MAINTAIN = 0;
const INCISORS_LOWER_HORIZ_OVERLAP_RETROCLINATE = 1;
const INCISORS_LOWER_HORIZ_OVERLAP_PROCLINATE = 2;
const INCISORS_LOWER_HORIZ_OVERLAP_NA = 3;

const OVERJET_MAINTAIN = 0;
const OVERJET_CONTACT = 1;
const OVERJET_MAINTAIN_LARGE = 2;
const OVERJET_CORRECT = 3;

const MIDLINE_MAINTAIN = 0;
const MIDLINE_IMPROVE = 1;
const ADJUST_LOWER_TO_UPPER = 2;
const ADJUST_UPPER_TO_LOWER = 3;

const CANINES_RATIO_RIGHT_CLASS_1 = 1;
const CANINES_RATIO_RIGHT_CLASS_2 = 2;
const CANINES_RATIO_RIGHT_CLASS_3 = 3;

const CANINES_RATIO_LEFT_CLASS_1 = 1;
const CANINES_RATIO_LEFT_CLASS_2 = 2;
const CANINES_RATIO_LEFT_CLASS_3 = 3;

const CANINES_RATIO_HOWTO_DISTALIZE = 1;
const CANINES_RATIO_HOWTO_MESIALIZE = 2;

const CANINES_RATIO_HOWTO_IPR_FALSE = false;
const CANINES_RATIO_HOWTO_IPR_TRUE = true;

const CANINES_RATIO_AI_FALSE = false;
const CANINES_RATIO_AI_TRUE = true;

const MOLARS_RATIO_RIGHT_MAINTAIN = 0;
const MOLARS_RATIO_RIGHT_CLASS_1 = 1;
const MOLARS_RATIO_RIGHT_CLASS_2 = 2;
const MOLARS_RATIO_RIGHT_CLASS_3 = 3;

const MOLARS_RATIO_LEFT_CLASS_1 = 1;
const MOLARS_RATIO_LEFT_CLASS_2 = 2;
const MOLARS_RATIO_LEFT_CLASS_3 = 3;

const MOLARS_RATIO_HOWTO_DISTALIZE = 1;
const MOLARS_RATIO_HOWTO_MESIALIZE = 2;

const MOLARS_RATIO_HOWTO_IPR_FALSE = false;
const MOLARS_RATIO_HOWTO_IPR_TRUE = true;

const MOLARS_RATIO_AI_FALSE = false;
const MOLARS_RATIO_AI_TRUE = true;

const ELASTIC_RECOIL_RIGHT_CLASS_1 = 1;
const ELASTIC_RECOIL_RIGHT_CLASS_2 = 2;
const ELASTIC_RECOIL_RIGHT_CLASS_3 = 3;

const ELASTIC_RECOIL_LEFT_CLASS_1 = 1;
const ELASTIC_RECOIL_LEFT_CLASS_2 = 2;
const ELASTIC_RECOIL_LEFT_CLASS_3 = 3;

const CLOSE_ALL_GAPS_YES = 1;
const CLOSE_ALL_GAPS_MAINTAIN = 2;
const CLOSE_ALL_GAPS_LEAVE_SPACE = 3;

export const MATERIAL_NA = 0;
export const MATERIAL_IMPRESSIONS = 1;
export const MATERIAL_SCAN = 2;

const RX_OPTION_TRANSLATIONS = {
	arch: {
		[ARCH_BOTH]: 'TA_BOTH',
		[ARCH_UPPER]: 'TA_UPPER',
		[ARCH_LOWER]: 'TA_LOWER'
	},
    upper_arch_action: {
		[UPPER_ARCH_MAINTAIN]: 'TA_U_MAINTAIN',
		[UPPER_ARCH_EXPAND]: 'TA_U_EXPAND',
		[UPPER_ARCH_NARROW]: 'TA_U_NARROW',
		[UPPER_ARCH_NA]: 'NA'
	},
	lower_arch_action: {
		[LOWER_ARCH_MAINTAIN]: 'TA_L_MAINTAIN',
		[LOWER_ARCH_EXPAND]: 'TA_L_EXPAND',
		[LOWER_ARCH_NARROW]: 'TA_L_NARROW',
		[LOWER_ARCH_NA]: 'NA'
	},
	teeth_level_align: {
		[TEETH_LEVEL_ALIGN_MARGIN]: 'TLA_G_MARGIN',
		[TEETH_LEVEL_ALIGN_EDGE]: 'TLA_C_EDGE'
	},
	vertical_overlap: {
		[INCISORS_VERT_OVERLAP_MAINTAIN]: 'VO_MAINTAIN',
		[INCISORS_VERT_OVERLAP_DECREASE]: deployedUSA() === true ? 'VO_DECREASE_USA': 'VO_DECREASE',
		[INCISORS_VERT_OVERLAP_INCREASE]: deployedUSA() === true ? 'VO_INCREASE_USA': 'VO_INCREASE',
		[INCISORS_VERT_OVERLAP_NA]: 'NA'
	},
	upper_horizontal_overlap: {
		[INCISORS_UPPER_HORIZ_OVERLAP_MAINTAIN]: 'HO_U_MAINTAIN',
		[INCISORS_UPPER_HORIZ_OVERLAP_RETROCLINATE]: 'HO_U_RETROCLINATE',
		[INCISORS_UPPER_HORIZ_OVERLAP_PROCLINATE]: 'HO_U_PROCLINATE',
		[INCISORS_UPPER_HORIZ_OVERLAP_NA]: 'NA'
	},
	lower_horizontal_overlap: {
		[INCISORS_LOWER_HORIZ_OVERLAP_MAINTAIN]: 'HO_L_MAINTAIN',
		[INCISORS_LOWER_HORIZ_OVERLAP_RETROCLINATE]: 'HO_L_RETROCLINATE',
		[INCISORS_LOWER_HORIZ_OVERLAP_PROCLINATE]: 'HO_L_PROCLINATE',
		[INCISORS_LOWER_HORIZ_OVERLAP_NA]: 'NA'
	},
	overjet: {
		[OVERJET_MAINTAIN]: 'OJ_MAINTAIN',
		[OVERJET_CONTACT]: 'OJ_CONTACT',
		[OVERJET_MAINTAIN_LARGE]: 'OJ_MAINTAIN_LARGE',
		[OVERJET_CORRECT]: 'OJ_MAINTAIN_CORRECT'
	},
	midline: {
		[MIDLINE_MAINTAIN]: 'ML_MAINTAIN',
		[MIDLINE_IMPROVE]: 'ML_IMPROVE',
		[ADJUST_LOWER_TO_UPPER]: 'ML_ADJUST_LOWER_TO_UPPER',
		[ADJUST_UPPER_TO_LOWER]: 'ML_ADJUST_UPPER_TO_LOWER'
	},
	canine_ratio_right: {
		[CANINES_RATIO_RIGHT_CLASS_1]: 'CR_R_I_CLASS',
		[CANINES_RATIO_RIGHT_CLASS_2]: 'CR_R_II_CLASS',
		[CANINES_RATIO_RIGHT_CLASS_3]: 'CR_R_III_CLASS'
	},
	canine_ratio_left: {
		[CANINES_RATIO_LEFT_CLASS_1]: 'CR_L_I_CLASS',
		[CANINES_RATIO_LEFT_CLASS_2]: 'CR_L_II_CLASS',
		[CANINES_RATIO_LEFT_CLASS_3]: 'CR_L_III_CLASS'
	},
	canine_method: {
		[CANINES_RATIO_HOWTO_DISTALIZE]: 'CRH_DISTALIZE',
		[CANINES_RATIO_HOWTO_MESIALIZE]: 'CRH_MESIALIZE'
	},
	canine_ipr: {
		[CANINES_RATIO_HOWTO_IPR_FALSE]: 'IPR_FALSE',
		[CANINES_RATIO_HOWTO_IPR_TRUE]: 'IPR_TRUE'
	},
	canine_method_ai: {
		[CANINES_RATIO_AI_FALSE]: 'AI_FALSE',
		[CANINES_RATIO_AI_TRUE]: 'CRH_AI'
	},
	molars_ratio_right: {
		[MOLARS_RATIO_RIGHT_MAINTAIN]: 'MR_R_MAINTAIN',
		[MOLARS_RATIO_RIGHT_CLASS_1]: 'MR_R_I_CLASS',
		[MOLARS_RATIO_RIGHT_CLASS_2]: 'MR_R_II_CLASS',
		[MOLARS_RATIO_RIGHT_CLASS_3]: 'MR_R_III_CLASS'
	},
	molars_ratio_left: {
		[MOLARS_RATIO_LEFT_CLASS_1]: 'MR_L_I_CLASS',
		[MOLARS_RATIO_LEFT_CLASS_2]: 'MR_L_II_CLASS',
		[MOLARS_RATIO_LEFT_CLASS_3]: 'MR_L_III_CLASS'
	},
	molars_method: {
		[MOLARS_RATIO_HOWTO_DISTALIZE]: 'CRH_DISTALIZE',
		[MOLARS_RATIO_HOWTO_MESIALIZE]: 'CRH_MESIALIZE'
	},
	molars_ipr: {
		[MOLARS_RATIO_HOWTO_IPR_FALSE]: 'IPR_FALSE',
		[MOLARS_RATIO_HOWTO_IPR_TRUE]: 'IPR_TRUE'
	},
	molars_method_ai: {
		[MOLARS_RATIO_AI_FALSE]: 'AI_FALSE',
		[MOLARS_RATIO_AI_TRUE]: 'CRH_AI'
	},
	close_all_gaps: {
		[CLOSE_ALL_GAPS_YES]: 'CAG_YES',
		[CLOSE_ALL_GAPS_MAINTAIN]: 'CAG_MAINTAIN',
		[CLOSE_ALL_GAPS_LEAVE_SPACE]: 'CAG_LEAVE_SPACE'
	},
	elastics_recoil_right: {
		[ELASTIC_RECOIL_RIGHT_CLASS_1]: 'ER_R_I_CLASS',
		[ELASTIC_RECOIL_RIGHT_CLASS_2]: 'ER_R_II_CLASS',
		[ELASTIC_RECOIL_RIGHT_CLASS_3]: 'ER_R_III_CLASS'
	},
	elastics_recoil_left: {
		[ELASTIC_RECOIL_LEFT_CLASS_1]: 'ER_L_I_CLASS',
		[ELASTIC_RECOIL_LEFT_CLASS_2]: 'ER_L_II_CLASS',
		[ELASTIC_RECOIL_LEFT_CLASS_3]: 'ER_L_III_CLASS'
	},
	material: {
		[MATERIAL_NA]: 'NA',
		[MATERIAL_IMPRESSIONS]: 'C_IMPRESSIONS',
		[MATERIAL_SCAN]: 'C_SCAN'
	}
}

export function translateRxOption(key, option) {
    if (option === null || option === undefined) {
        return "None";
    }
    return RX_OPTION_TRANSLATIONS[key][option];
}

export const INITIAL_VALUES_PRESCRIPTION: TPrescription =  {
	material: null,
	arch: null,
	upper_arch_action: null,
	lower_arch_action: null,
	teeth_level_align: null,
	vertical_overlap: null,
	vertical_overlap_comment: "",
	upper_horizontal_overlap: null,
	lower_horizontal_overlap: null,
	overjet: null,
	correct_retroclination_upper: false,
	correct_proclination_lower: false,
	correct_proclination_upper: false,
	correct_retroclination_lower: false,
	midline: null,
	midline_improve_by: "",
	canine_ratio_right: null,
	canine_ratio_left: null,
	canine_method: null,
    canine_method_ai: false,
	canine_ipr: false,
	molars_ratio_right: null,
	molars_ratio_left: null,
	molars_method: null,
	molars_method_ai: false,
	molars_ipr: false,
	close_all_gaps: null,
	close_all_gaps_value: "",
	elastics_recoil_right: null,
	elastics_recoil_left: null,
	elastics_options: {},
	comment: "",
	upper_arch_expansion_bodily: false,
	upper_arch_expansion_torque: false,
	lower_arch_expansion_bodily: false,
	lower_arch_expansion_torque: false,
	dont_move: [],
	avoid_locks: [],
	extractions: [],
	teething: [],
	close_all_gaps_spaces: [{}]
}
