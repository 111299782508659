import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';
import { Field, getFormValues } from 'redux-form';

import { convertToDentalNotation } from "~/common/utils";
import type { RootState } from '~/store';

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    formValues: getFormValues('correction')(state),
  }
};

const validateFloatNumber = (value) => {
  const regEx = /^[+]?(0|[1-9]\d*)(\.[0-9]{1,2})?$/;
  if (typeof value !== 'undefined' && value !== '0' && regEx.test(value) ) {
    return false
  }
  return true
}

const renderSelectInput = ({ input, className, options, name, selectedTeeth }) => {
  return (
    <select
      {...input}
      name={name}
      className={className}
    >
    <option>{''}</option>
    {options.map((option, index) =>
      <option
        style={{display: selectedTeeth.includes(option.value) ? "none" : "block"}}
        key={index}
        value={option.value}
        label={option.label}>
        {option.label}
      </option>
    )}
    </select>
  )
}

const  renderFieldValidation = ({input, meta, ...props}) => {
  const error = meta.touched && meta.error
  return (
    <div className={ error ? 'has-error' : ''}>
      <input {...input} {...props}/>
    </div>
  );
};

const RenderField = ({...props}) =>  {
  const { options, selectedTeeth, item, index, getValue,
    fields, getIndex, close_all_gaps_spaces,
    hideButton, showButton, resetForm } = props;

  function removeField() {
    if (fields.length > 1) {
      getIndex(index);
      fields.remove(index);
      showButton(fields.length - 2);
    } else {
      resetForm('close_all_gaps_spaces', [{}])
    }
  }

  function addField() {
    hideButton(index);
    if (fields.length <= (options.length - 1)) {
      fields.push({});
    }
  }

    return (
      <div className="row" key={index}>
        <button
          style={{marginRight: "5px"}}
          className="btn btn-danger"
          type="button"
          onClick={removeField}
        >-</button>
        <div className="col-md-3 col-xs-4 col-sm-4">
          <Field
            className="form-control"
            component={renderSelectInput}
            options={options}
            selectedTeeth={selectedTeeth}
            name={`${item}tooth`}
            index={item}
            onChange={e => getValue(e.target.value)}
          />
          <label  style={{fontSize: "12px", fontStyle: "italic"}}>
            (<FormattedMessage id="TOOTH_NUMBER" />)
          </label>
        </div>
        <div className="col-md-2 col-xs-3 col-sm-2">
          <Field
            disabled={!close_all_gaps_spaces[index].hasOwnProperty('tooth')}
            className="form-control"
            component={renderFieldValidation}
            type="number"
            min={0}
            name={`${item}distance`}
            validate={[validateFloatNumber]}
            normalize={v => v.replace(/^-\d+$/, '')}
          />
          <label className={`validation-distance${index}`} style={{fontSize: "12px", fontStyle: "italic"}}>
            (<FormattedMessage id="DISTANCE_IN_MILLIMETRES" />)
            <span className="required" aria-required="true">*</span>
          </label>
        </div>
        <button
              id={index}
              className="btn btn-success"
              type="button"
              onClick={addField}
        >+</button>
      </div>
    );
}

type CloseAllGapsSpacesProps = PropsFromRedux & { intl: IntlShape };

class CloseAllGapsSpaces extends Component<CloseAllGapsSpacesProps> {
  constructor(props: CloseAllGapsSpacesProps) {
    super(props);
    const quads = [1, 2, 4, 3];
    this.teeth = quads.map((quad) => {
      const arr = [];
      for (let i = 1; i <= 8; i++) {
        arr.push(quad*10 + i);
      }
      if (quad === 1 || quad === 4) arr.reverse();
      return arr;
    });
    this.state = {
      arr: []
    }
    this.getValue = this.getValue.bind(this);
    this.getIndex = this.getIndex.bind(this);
    this.hideButton = this.hideButton.bind(this);
    this.showButton = this.showButton.bind(this);
  }

  componentDidMount() {
    const { close_all_gaps_spaces } = this.props.patient && Object.keys(this.props.patient).length > 0 && this.props.patient.course.correction
      && this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
      && this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription !== null
      ? this.props.patient.course.correction[this.props.patient.course.correction.length - 1].prescription
      : {}
    const selected = close_all_gaps_spaces ? Object.keys(close_all_gaps_spaces).map(el => Number(el)) : [];
    selected.forEach((i, index) => { (selected.length - 1) !== index ? this.hideButton(index) : this.showButton(index)})
    this.setState({ arr: selected });
  }

  getValue(v) {
    const { arr } = this.state;
    this.setState({ arr: [...arr, Number(v)] });
  }

  getIndex(v) {
    const { arr } = this.state;
    arr.splice(v, 1);
  }

  hideButton(index) {
    $(`#${index}`).css('display', 'none');
  }

  showButton(index) {
    $(`#${index}`).css('display', 'inline-block');
  }

  render() {
    const {user, fields, resetForm } = this.props;
    let { dentalNotation } = this.props;
    const { close_all_gaps_spaces } = this.props.formValues;
    const selectedTeeth = close_all_gaps_spaces.map(i => parseInt(i.tooth))
    if (!dentalNotation && user.preferences) dentalNotation = user.preferences.dental_notation;
    const options = [];
    this.teeth.forEach(function (el) {
      el.forEach(function (item) {
          options.push({label: convertToDentalNotation(item, dentalNotation), value: item})
      })
    });

    return (
      <div style={{display: "inline-block", width: "100%", marginBottom: "20px"}} >
        {fields.map((item, index) =>
          <RenderField
            selectedTeeth={selectedTeeth}
            dentalNotation={dentalNotation}
            item={item}
            index={index}
            key={index}
            fields={fields}
            options={options}
            getValue={this.getValue}
            getIndex={this.getIndex}
            close_all_gaps_spaces={close_all_gaps_spaces}
            hideButton={this.hideButton}
            showButton={this.showButton}
            resetForm={resetForm}
          />)}
      </div>
    )
  }
};

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(CloseAllGapsSpaces));
