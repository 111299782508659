import React, { Component, createRef } from "react";
import { FormattedMessage } from "react-intl";
import Modal from "react-modal";
import { connect, type ConnectedProps } from "react-redux";
import invariant from "tiny-invariant";

import { sendQuestion } from "~/actions/comments";
import { Button } from "~/components/ui/button";
import type { RootState } from "~/store";

const mapDispatchToProps = {
  sendQuestion,
};

const mapStateToProps = (state: RootState) => ({
  patient: state.patient,
});

type PatientAskQuestionProps = PropsFromRedux;

type PatientAskQuestionState = {
  question: string;
  isModalOpened: boolean;
};

class PatientAskQuestion extends Component<PatientAskQuestionProps, PatientAskQuestionState> {
  questionInputRef = createRef<HTMLTextAreaElement>();
  openModalButtonRef = createRef<HTMLButtonElement>();

  constructor(props: PatientAskQuestionProps) {
    super(props);
    this.state = {
      question: "",
      isModalOpened: false,
    };
  }

  openModal() {
    this.setState({ isModalOpened: true });
  }

  closeModal() {
    this.setState({ isModalOpened: false, question: "" });
  }

  afterOpenModal() {
    if (this.questionInputRef.current) {
      this.questionInputRef.current.focus();
    }
  }

  afterCloseModal() {
    if (this.openModalButtonRef.current) {
      this.openModalButtonRef.current.focus();
    }
  }

  sendQuestion() {
    const { question } = this.state;
    const trimmedQuestion = question.trim();
    if (trimmedQuestion.length > 0) {
      invariant(
        this.props.patient.patient_id != undefined,
        "expected patient_id, but got undefined"
      );
      this.props.sendQuestion(this.props.patient.patient_id, trimmedQuestion);
    }
    this.setState({ question: "" });
    this.closeModal();
  }

  render() {
    const { question, isModalOpened } = this.state;
    const questionHasInvalidCharacters = question.includes(">") || question.includes("<");
    const isQuestionSubmitDisabled = question.trim() == "" || questionHasInvalidCharacters;

    return (
      <div>
        <Button
          id="add-comment-btnnn comment-button-add-question"
          variant="blue"
          ref={this.openModalButtonRef}
          style={{ margin: 10 }}
          className="tw-whitespace-pre-line"
          type="button"
          onClick={() => this.openModal()}
        >
          <FormattedMessage id="pat.question.button" />
        </Button>

        <Modal
          className="question-modal"
          isOpen={isModalOpened}
          onRequestClose={() => this.closeModal()}
          onAfterOpen={() => this.afterOpenModal()}
          onAfterClose={() => this.afterCloseModal()}
          style={{ overlay: { backgroundColor: "#787d7d7d" } }}
        >
          <form
            id="comment-question-modal"
            className="portlet-body form"
            onSubmit={(event) => {
              event.preventDefault();
              this.sendQuestion();
            }}
          >
            <div className="form-body" style={{ paddingBottom: 0 }}>
              <label
                id="comment-question-modal-label"
                className="control-label"
                htmlFor="comment-question-modal-input"
                style={{ paddingBottom: 15 }}
              >
                <FormattedMessage id="pat.question.modal.title" />
              </label>
              <textarea
                ref={this.questionInputRef}
                id="comment-question-modal-input"
                className="form-control"
                rows={5}
                name="question"
                value={question}
                onChange={(event) => this.setState({ question: event.currentTarget.value })}
                aria-invalid={questionHasInvalidCharacters ? "true" : undefined}
                aria-errormessage={
                  questionHasInvalidCharacters ? "comment-question-modal-warning" : undefined
                }
              />
            </div>

            <div
              id="comment-question-modal-warning"
              className="text-danger"
              style={{ marginLeft: 20, fontSize: 13, padding: 10, minHeight: 40 }}
              role="alert"
            >
              {questionHasInvalidCharacters ? (
                <FormattedMessage id="pat.question.modal.restricted.sym" />
              ) : null}
            </div>

            <div
              id="comment-question-modal-buttons"
              className="form-actions"
              style={{ backgroundColor: "white" }}
            >
              <Button
                id="add-comment-btnnn comment-question-modal-submit"
                style={{ margin: 10 }}
                type="submit"
                disabled={isQuestionSubmitDisabled}
              >
                <FormattedMessage id="BTN_SUBMIT" />
              </Button>

              <Button
                id="add-comment-btnnn comment-question-modal-cancel"
                variant="grey"
                style={{ margin: 10 }}
                type="button"
                onClick={() => this.closeModal()}
              >
                <FormattedMessage id="pat.deliveries.button.cancel" />
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientAskQuestion);
