import React, { Component } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import { connect, type ConnectedProps } from "react-redux";
import translit from "translitit-cyrillic-russian-to-latin";

import { getComments, sendComment } from "~/actions/comments";
import { eraseStateProp } from "~/actions/dashboard";
import {
  COMMENT_TYPE_OPTIONS,
  DN_MAP_PALMER,
  PLAN_LANG_OPTION_MAP,
} from "~/common/constants";
import { Status } from "~/common/courses";
import { deployedUSA } from "~/common/utils";
import { FormatDate } from "~/components/common/FormatDate";
import Loader from "~/components/common/loadingInProgress";
import { formatPersonName } from "~/components/common/PersonName";
import { Button } from "~/components/ui/button";
import { Img } from "~/components/ui/image";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { API_MEETINGS_IMAGE, PLAN_LINK_V2, PLAN_LINK_V2_RU } from "~/config";
import type { TPatient } from "~/reducers/dashboard";
import type { TComment, TUser } from "~/reducers/login";
import type { RootState } from "~/store";

import PatientAskQuestion from "../patient_ask_question";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    intl: state.intl,
    comments: state.comments,
    token: state.token,
  };
};

const mapDispatchToProps = {
  getComments,
  sendComment,
  eraseStateProp,
};

function TranslitFromCyrillic({ id, locale }: { id: string; locale: string }) {
  if (locale !== "ru") {
    return <span>{translit(id)}</span>;
  } else {
    return <span>{id}</span>;
  }
}

type PatientCommentsSmileProps = PropsFromRedux & { patient: TPatient };

type PatientCommentsSmileState = {
  comments: TComment[];
  question: unknown[];
  canSubmit: boolean;
  showLoader: boolean;
};

class PatientCommentsSmile extends Component<PatientCommentsSmileProps, PatientCommentsSmileState> {
  comment: HTMLTextAreaElement | null = null;

  constructor(props: PatientCommentsSmileProps) {
    super(props);
    this.state = {
      comments: [],
      question: [],
      canSubmit: true,
      showLoader: false,
    };
    this.sendComment = this.sendComment.bind(this);
  }

  componentDidMount() {
    Modal.setAppElement("body");
    this.props.getComments(this.props.patient.patient_id);
  }

  UNSAFE_componentWillReceiveProps(nextProps: PatientCommentsSmileProps) {
    if (Object.keys(nextProps.comments).length === 0) {
      this.setState({ comments: [] });
    } else {
      this.setState({ comments: nextProps.comments });
      this.setState({ canSubmit: true });
      this.setState({ showLoader: false });
    }
  }

  componentWillUnmount() {
    this.props.eraseStateProp("comments");
  }

  sendComment() {
    if (this.comment && this.comment.value !== "") {
      if (this.comment.value != "") {
        this.props.sendComment(this.props.patient.patient_id, this.comment.value);
      }
      this.comment.value = "";
      this.setState({ canSubmit: false });
      this.setState({ showLoader: true });
    }
  }

  open3dPlan() {
    const { user } = this.props;
    let langLocale = this.props.intl.locale;
    if (deployedUSA() === true) {
      langLocale =
        PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] ||
        PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(
        PLAN_LINK_V2(this.props.patient.patient_id, this.props.token, langLocale),
      );
    } else {
      window.open(
        PLAN_LINK_V2_RU(this.props.patient.patient_id, this.props.token, langLocale),
      );
    }
  }

  render() {
    const { patient, comments } = this.props;

    const hideCommentInput = patient && patient.status == Status._3D_PLAN_APPROVAL;
    const commentsAreLoading = !Array.isArray(comments) && Object.keys(comments).length == 0;

    const maxSkeletons = 3;
    const numberOfSkeletons = Math.min(patient.num_comments, maxSkeletons);

    return (
      <Portlet id="comment-block">
        <PortletTitle as="h2" iconClassName="icon-speech" id="comment-title">
          <FormattedMessage id="pat.comment.title" />
        </PortletTitle>

        <div className="portlet-body form">
          {!hideCommentInput ? (
            <div className="form-body">
              <div className="alert alert-danger display-hide">
                <button className="close" data-close="alert" />
                <span id="error-msg" />
              </div>
              <div className="form-group" id="comment-form-non-mobile">
                <label
                  htmlFor="comment-form-non-mobile-input"
                  className="control-label"
                  id="comment-form-non-mobile-label"
                >
                  <FormattedMessage id="pat.comment.button" />
                </label>
                <textarea
                  data-matomo-mask
                  className="form-control max-sm:tw-text-base"
                  id="comment-form-non-mobile-input"
                  rows={5}
                  name="comment"
                  defaultValue=""
                  ref={(input) => (this.comment = input)}
                />
              </div>
            </div>
          ) : (
            <div className="alert alert-warning" id="comment-form-mobile">
              <span style={{ color: "black" }} id="comment-form-mobile-warning">
                <FormattedMessage id="comment.message.on.plan.approve" />
              </span>
              .&nbsp;
              {this.props.token && this.props.patient.media["3d-plan"].response ? (
                <button
                  id="comment-form-mobile-plan-button"
                  onClick={() => this.open3dPlan()}
                  style={{
                    color: "#008996",
                    backgroundColor: "transparent",
                    border: "none",
                    padding: 0,
                  }}
                >
                  <u>
                    <FormattedMessage id="pat.plan.button" />
                  </u>
                </button>
              ) : null}
            </div>
          )}

          <div className="form-actions">
            {!hideCommentInput ? (
              <Button
                id="add-comment-btnnn comment-button-add-comment"
                type="submit"
                onClick={() => this.sendComment()}
                disabled={!this.state.canSubmit}
                style={{ margin: "10px" }}
              >
                <FormattedMessage id="pat.comment.button" />
              </Button>
            ) : null}
            {this.props.patient.status != Status._3D_PLAN_APPROVAL ? <PatientAskQuestion /> : false}
            {this.state.showLoader ? <Loader /> : null}
          </div>
        </div>

        <div className="portlet-body">
          <div className="timeline">
            {commentsAreLoading
              ? Array.from({ length: numberOfSkeletons }, (_, index) => (
                <Comment key={index} user={this.props.user} comment={undefined} />
              ))
              : this.state.comments.map((comment, i) => (
                  <Comment key={i} user={this.props.user} comment={comment} />
                ))}
          </div>
        </div>
      </Portlet>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientCommentsSmile);

function Comment({ user, comment }: { user: TUser; comment: TComment | undefined }) {
  const intl = useIntl();

  const { color: hintColor = "", translation: hintIntlId = "" } = comment
    ? COMMENT_TYPE_OPTIONS[comment.type] ?? {}
    : {};

  const imageLoadingElement = <Skeleton width={75} height={75} className="tw-rounded-full" />;

  const employeeImageElement = (
    <div className="timeline-icon">
      <i className="icon-user font-purple" />
    </div>
  );

  return (
    <div className="timeline-item" id="comment-body">
      <div className="timeline-badge">
        {comment == undefined ? (
          imageLoadingElement
        ) : comment.commenter.avatar ? (
          <Img
            width={75}
            height={75}
            errorFallback={employeeImageElement}
            loadingFallback={imageLoadingElement}
            src={API_MEETINGS_IMAGE(comment.account_id, comment.commenter.avatar)}
            alt=""
          />
        ) : comment.account_id == user.account_id ? (
          <div className="timeline-icon">
            <i className="icon-user font-red-haze" />
          </div>
        ) : (
          employeeImageElement
        )}
      </div>

      <div className="timeline-body">
        <div className="timeline-body-arrow"></div>

        <div className="timeline-body-head">
          <div className="timeline-body-head-caption">
            <span
              data-matomo-mask
              data-hj-suppress
              className="timeline-body-title font-blue-madison"
              id="comment-block-authon"
            >
              {comment == undefined ? null : comment.account_id == user.account_id ? (
                <TranslitFromCyrillic
                  id={formatPersonName({
                    person: { ...comment.commenter, account_id: comment.account_id },
                    user,
                  })}
                  locale={intl.locale}
                />
              ) : comment.commenter.external ? (
                comment.commenter.full_name
              ) : (
                <TranslitFromCyrillic
                  id={formatPersonName({
                    person: { ...comment.commenter, account_id: comment.account_id },
                    user,
                  })}
                  locale={intl.locale}
                />
              )}
            </span>
            &nbsp;
            {comment ? (
              <FormatDate
                className="timeline-body-time font-grey-cascade"
                value={comment.timestamp}
                date
              />
            ) : null}
          </div>

          <div className="timeline-body-head-actions">
            {hintColor && hintIntlId ? (
              <span style={{ color: hintColor, fontWeight: "600", fontSize: "12px" }}>
                <FormattedMessage id={hintIntlId} />
              </span>
            ) : null}
          </div>
        </div>

        <div className="timeline-body-content">
          <span
            data-matomo-mask
            data-hj-suppress
            style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
            className="font-grey-cascade"
            id="comment-block-content"
          >
            {comment ? comment.comment : <Skeleton containerClassName="tw-space-y-1" count={2} />}
          </span>
        </div>
      </div>
    </div>
  );
}
