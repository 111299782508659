import React from "react";
import { FormattedMessage } from "react-intl";

import FileUpload from "~/components/file_upload";

import { RemoteLogErrorBoundary } from "../common/remote-log-error-boundary";

function PlanCorrectionPhotoErrorBoundary(props: React.ComponentProps<typeof PlanCorrectionPhoto>) {
  return (
    <RemoteLogErrorBoundary component="3d_plan_correction_photo">
      <PlanCorrectionPhoto {...props} />
    </RemoteLogErrorBoundary>
  );
}

function PlanCorrectionPhoto({ correctionNumber }: { correctionNumber: number }) {
  return (
    <div data-matomo-mask data-hj-suppress className="form-body" id="photo-protocol-block">
      <div className="row">
        <div className="form-group col-md-4">
          <label htmlFor="profile" className="control-label" style={{ fontWeight: "900" }}>
            <FormattedMessage id="PHOTO_PROFILE" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="profile"
            preview
            id="profile"
            semantics="profile"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>

        <div className="form-group col-md-4">
          <label
            htmlFor="full_face_with_smile"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_SMILE" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="full_face_with_smile"
            preview
            id="full_face_with_smile"
            semantics="full_face_with_smile"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>

        <div className="form-group col-md-4">
          <label
            htmlFor="full_face_without_smile"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_PORTRATE" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="full_face_without_smile"
            preview
            id="full_face_without_smile"
            semantics="full_face_without_smile"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4" style={{ marginBottom: 10 }}>
          <label
            htmlFor="occlusal_view_upper"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="occlusal_view_upper"
            preview
            id="occlusal_view_upper"
            semantics="occlusal_view_upper"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>

        <div className="form-group col-md-4"></div>

        <div className="form-group col-md-4">
          <label
            htmlFor="occlusal_view_lower"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="occlusal_view_lower"
            preview
            id="occlusal_view_lower"
            semantics="occlusal_view_lower"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4">
          <label
            htmlFor="lateral_view_right"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_RIGHT" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="lateral_view_right"
            preview
            id="lateral_view_right"
            semantics="lateral_view_right"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>

        <div className="form-group col-md-4">
          <label htmlFor="front_view" className="control-label" style={{ fontWeight: "900" }}>
            <FormattedMessage id="PHOTO_FRONT" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="front_view"
            preview
            id="front_view"
            semantics="front_view"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>

        <div className="form-group col-md-4">
          <label
            htmlFor="lateral_view_left"
            className="control-label"
            style={{ fontWeight: "900" }}
          >
            <FormattedMessage id="PHOTO_LEFT" />
            <span className="required" aria-required="true">
              *
            </span>
          </label>
          <br />
          <FileUpload
            name="lateral_view_left"
            preview
            id="lateral_view_left"
            semantics="lateral_view_left"
            patient_id={0}
            correctionNum={correctionNumber}
          />
        </div>
      </div>
    </div>
  );
}

export default PlanCorrectionPhotoErrorBoundary;
