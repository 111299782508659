import "../css/FileUpload.css";

import exif from 'exif-js';
import React, { Component } from "react";
import Dropzone, { type FileWithPreview } from "react-dropzone";
import { FormattedMessage } from "react-intl";
import NotificationSystem from "react-notification-system";
import { connect, type ConnectedProps } from "react-redux";

import { handleDropped } from "~/actions/dashboard";
import rotateLeft from "~/assets/img/rotateLeft.svg";
import rotateRight from "~/assets/img/rotateRight.svg";
import remoteLog from "~/common/logging";
import { API_PATIENT_IMAGE } from "~/config";
import en from "~/locale/en";
import ru from "~/locale/ru";
import type { TCorrectionMediaSemantics } from "~/reducers/dashboard";
import type { RootState } from "~/store";

const DROP_ZONE_WIDTH = 180;
const DROP_ZONE_HEIGHT = 135;
const MAX_BROAD_SIDE_WIDTH = 2000;

const mapStateToProps = (state: RootState) => {
  return {
    media: state.media,
    patient: state.patient,
  }
}

const mapDispatchToProps = {
  handleDropped,
};

type FileUploadProps = PropsFromRedux & {
  id: TCorrectionMediaSemantics;
  semantics: TCorrectionMediaSemantics;
  preview?: boolean;
  correctionNum?: number;
  onFileUploadEnd?(): void;
  patient_id: 0;
};

type DropZoneItem = {
    canvas: HTMLCanvasElement;
    ctx: CanvasRenderingContext2D | null;
    name: string;
    image: HTMLImageElement;
    render?: React.ReactElement;
    rotation?: number;
};

class FileUpload extends Component<FileUploadProps> {
  _notificationSystem: NotificationSystem | null = null;
  dropZones: Record<string, DropZoneItem>;

  constructor(props: FileUploadProps) {
    super(props);
    this._notificationSystem = null;
    this.dropZones = {};
  }

  componentDidCatch(e: Error) {
    remoteLog(e, 'file_upload');
  }

  showFileInputThumbnail() {
    const file = this.props.media[this.props.id]
    const dz_width = "180px"
    const dz_height = "72px"

    if (file && file.user_filename) {
      const file_extension = file.user_filename.split(".").slice(-1)[0].toUpperCase();
      const imgUrl = "/img/upload_placeholders/THUMBNAIL_"+file_extension+".svg" + `?${Number(new Date())}`
      // if (file.img) imgUrl = file.img.preview;
      return (
        <div className="flex-row" style={{ marginBottom: "16px" }}>
          <div className="col col--shrink">
            <div data-matomo-mask data-hj-suppress className="FileUpload">
              <Dropzone
                id={this.props.id}
                multiple={false}
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "let",
                  justifyContent: "left",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div
                  data-matomo-mask data-hj-suppress
                  style={{
                    width: dz_width,
                    height: dz_height,
                    backgroundImage: `url(${imgUrl})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    overflow: "hidden",
                    float: "left",
                  }}
                />
              </Dropzone>
              <NotificationSystem ref={(notificationSystem) => this._notificationSystem = notificationSystem} />
            </div>
          </div>

          <div data-matomo-mask data-hj-suppress className="col" style={{ display: "flex", alignItems: "center" }}>
            {file.user_filename}
          </div>
        </div>
      );
    } else {
      const imgUrl = "/img/upload_placeholders/recipe.svg"
      return (
        <div data-matomo-mask data-hj-suppress className="FileUpload">
          <Dropzone
            id={this.props.id}
            multiple={false}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onDrop={(accepted) => {

              if (
                accepted &&
                accepted[0] &&
                accepted[0].name
                  .split(".")
                  .pop()
                  .search(/jpg|jpeg|png|stl|pdf|ortho|mp4/gi) > -1
              ) {
                this.handleDropped(accepted, this.props.id);
              } else if (
                accepted[0].name
                  .split(".")
                  .pop()
                  .toLowerCase()
                  .search(/heic|heif/gi) > -1
              ) {
                this._notificationSystem.addNotification({
                  message: window.location.hostname.endsWith(".com")
                    ? en["notify.upload.E_UNSUPPORTED_HIEF"]
                    : ru["notify.upload.E_UNSUPPORTED_HIEF"],
                  level: "error",
                });
              } else {
                this._notificationSystem.addNotification({
                  message: window.location.hostname.endsWith(".com")
                    ? en["notify.upload.E_UNSUPPORTED_FORMAT_EXT"]
                    : ru["notify.upload.E_UNSUPPORTED_FORMAT_EXT"],
                  level: "error",
                });
              }
            }}
          >
            <div
              data-matomo-mask data-hj-suppress
              style={{
                width: dz_width,
                height: dz_height,
                backgroundImage: `url(${imgUrl})`,
                backgroundSize: "cover",
                overflow: "hidden",
                padding: "10px 80px 0 10px",
              }}
            >
              <FormattedMessage id="UPLOAD_FILE_BTN" />
            </div>
          </Dropzone>

          <div
            style={{
              position: "absolute",
              left: "-1px",
              bottom: "-1px",
              width: "calc(100% + 2px)",
              height: "5px",
            }}
          >
            <div
              id={`__PROGRESSBAR__-${this.props.id}`}
              className="__PROGRESSBAR__"
              style={{
                position: "absolute",
                width: "0",
                height: "100%",
                backgroundColor: "#00858A",
                transition: "width 0.1s",
              }}
            />
          </div>
          <NotificationSystem ref={(notificationSystem) => this._notificationSystem = notificationSystem} />
        </div>
      );
    }
  }

  handleDropped(accepted, id: FileUploadProps["id"], clbk = () => this.props.onFileUploadEnd()) {
    if (accepted.length > 0) {
      if (id) {
        $(`#__PROGRESSBAR__-${id}`).css({ width: "0%" });
      }
      this.props.handleDropped(
        accepted[0],
        this.props.patient_id,
        this.props.semantics,
        this.props.id,
        this.props.correctionNum,
        clbk,
      );
    }
  }

  enableDropZone(accepted: FileWithPreview, id: string) {
    if ((this.dropZones[id] || {}).canvas) {
      delete this.dropZones[id].canvas;
    }
    if (((((this.props || {}).patient || {}).media || {}).required_images || {})[id]) {
      this.props.patient.media.required_images[id] = false;
    }
    const _this = this;
    this.dropZones[id] = {};
    const item = this.dropZones[id];
    item.canvas = document.createElement('canvas');
    item.ctx = item.canvas.getContext('2d');
    item.name = accepted[0].name;
    item.image = new Image();
    item.image.src = accepted[0].preview;
    this.rotation = 0;
    item.image.onload = () => {
      if (item.image.width > item.image.height) {
        if (item.image.width > MAX_BROAD_SIDE_WIDTH) {
          item.image.height = MAX_BROAD_SIDE_WIDTH / item.image.width * item.image.height;
          item.image.width = MAX_BROAD_SIDE_WIDTH;
        }
      } else {
        if (item.image.height > MAX_BROAD_SIDE_WIDTH) {
          item.image.width = MAX_BROAD_SIDE_WIDTH / item.image.height * item.image.width;
          item.image.height = MAX_BROAD_SIDE_WIDTH;
        }
      }
      exif.getData(item.image, function() {
        const { Orientation } = exif.getAllTags(this);
        Orientation ? ({
          '1'() { _this.rotate(id, 0); },
          '2'() { _this.rotate(id, 0); },
          '3'() { _this.rotate(id, 2); },
          '4'() { _this.rotate(id, 0); },
          '5'() { _this.rotate(id, 0); },
          '6'() { _this.rotate(id, 1); },
          '7'() { _this.rotate(id, 0); },
          '8'() { _this.rotate(id, 3); },
        })[Orientation]() : _this.rotate(id, 0);
      });
    };
  }

  showDropzonePreview(id: FileUploadProps["id"]) {
    const dz_width = `${ DROP_ZONE_WIDTH }px`;
    const dz_height = `${ DROP_ZONE_HEIGHT }px`;
    const old_img = this.props.patient.media && this.props.patient.media.required_images ? this.props.patient.media.required_images[id] : null;
    if ((this.dropZones[id] || {}).render) {
      return this.dropZones[id].render;
    } else if (old_img && !(this.props.correctionNum)) {
      return (<div data-matomo-mask data-hj-suppress style={{
        width: dz_width ,
        height: dz_height,
        backgroundImage: `url(${ API_PATIENT_IMAGE(this.props.patient.patient_id, '') }${ old_img.thumbnail + `?${Number(new Date())}` })`,
        backgroundSize: 'cover',
        backgroundPosition: "center center",
        overflow: 'hidden',
      }} />)
    } else {
      return (<div data-matomo-mask data-hj-suppress style={{
        width: dz_width,
        height: dz_height,
        backgroundImage: `url(/img/upload_placeholders/${ this.props.semantics }.svg)`,
        backgroundSize: 'cover',
        overflow: 'hidden',
      }}><span style={{
        position: "relative",
        bottom: -110,
        left: 10
      }} ><FormattedMessage id="UPLOAD_BTN"/></span></div>)
    }
  }

  rotate(id: FileUploadProps["id"], _rotation: number) {
    const item = this.dropZones[id];
    item.rotation = _rotation;
    if (item.rotation === 4) {
      item.rotation = 0;
    }
    const { rotation, image, canvas, ctx, name } = item;
    let _width = image.width;
    let _height = image.height;
    if (rotation % 2) {
      _width = image.height;
      _height = image.width;
    }
    if (_width > _height) {
      _width = DROP_ZONE_HEIGHT / _height * _width;
      _height = DROP_ZONE_HEIGHT;
    } else {
      _height = _height / _width * DROP_ZONE_WIDTH;
      _width = DROP_ZONE_WIDTH;
    }
    canvas.width = DROP_ZONE_WIDTH;
    canvas.height = DROP_ZONE_HEIGHT;
    ctx.clearRect(0, 0, _width, _height);
    if (rotation === 2) {
      ctx.translate(_width, _height);
    } else if (rotation === 1) {
      ctx.translate(_width, 0);
    } else if (rotation === 3) {
      ctx.translate(0, _height);
    }
    ctx.rotate(Math.PI * 0.5 * rotation);
    if (image.width > image.height) {
      ([
        () => ctx.drawImage(image, (DROP_ZONE_WIDTH - _width) * 0.5, 0, _width, _height),
        () => ctx.drawImage(image, (DROP_ZONE_HEIGHT - _height) * 0.5, 0, _height, _width),
        () => ctx.drawImage(image, (_width - DROP_ZONE_WIDTH) * 0.5, 0, _width, _height),
        () => ctx.drawImage(image, (_height - DROP_ZONE_HEIGHT) * 0.5, 0, _height, _width)
      ])[rotation]();
    } else {
      ([
        () => ctx.drawImage(image, 0, (DROP_ZONE_HEIGHT - _height) * 0.5, _width, _height),
        () => ctx.drawImage(image, 0, (_width - DROP_ZONE_WIDTH) * 0.5, _height, _width),
        () => ctx.drawImage(image, 0, (_height - DROP_ZONE_HEIGHT) * 0.5, _width, _height),
        () => ctx.drawImage(image, 0, (DROP_ZONE_WIDTH - _width) * 0.5, _height, _width)
      ])[rotation]();
    }
    const dataURL = canvas.toDataURL('image/jpeg', 1);

    canvas.width = image.width;
    canvas.height = image.height;
    if (rotation % 2) {
      canvas.width = image.height;
      canvas.height = image.width;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (rotation === 2) {
      ctx.translate(canvas.width, canvas.height);
    } else if (rotation === 1) {
      ctx.translate(canvas.width, 0);
    } else if (rotation === 3) {
      ctx.translate(0, canvas.height);
    }
    ctx.rotate(Math.PI * 0.5 * rotation);
    if (image.width > image.height) {
      ([
        () => ctx.drawImage(image, 0, 0, canvas.width, canvas.height),
        () => ctx.drawImage(image, 0, 0, canvas.height, canvas.width),
        () => ctx.drawImage(image, 0, 0, canvas.width, canvas.height),
        () => ctx.drawImage(image, 0, 0, canvas.height, canvas.width)
      ])[rotation]();
    } else {
      ([
        () => ctx.drawImage(image, 0, 0, canvas.width, canvas.height),
        () => ctx.drawImage(image, 0, 0, canvas.height, canvas.width),
        () => ctx.drawImage(image, 0, 0, canvas.width, canvas.height),
        () => ctx.drawImage(image, 0, 0, canvas.height, canvas.width)
      ])[rotation]();
    }

    canvas.toBlob((blob) => {
      this.handleDropped([ new File([ blob ], name, { type: 'image/jpeg' }) ], id, () => {

        // const img = new Image();
        // $(img).css({
        //   position: 'fixed',
        //   top: 0,
        //   left: 0,
        //   'z-index': 999999,
        // });
        // img.src = canvas.toDataURL('image/jpeg', 1);
        // img.onload = () => document.body.appendChild(img);

        item.render = (<img data-matomo-mask data-hj-suppress src={ dataURL }/>);
        this.forceUpdate();
      });
    }, 'image/jpeg', 1);
  }

  render() {
    if (this.props.preview) {
      return (
        <div
          style={{
            position: "relative",
            width: "180px",
            height: "135px",
            backgroundColor: "white",
            margin: "5px",
            border: "solid silver 1px",
          }}
        >
          <Dropzone
            id={this.props.id}
            multiple={false}
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onDrop={(accepted) => {
              if (accepted && accepted[0] && accepted[0].type === "image/jpeg") {
                this.enableDropZone(accepted, this.props.id);
              } else if (
                accepted[0].name
                  .split(".")
                  .pop()
                  .toLowerCase()
                  .search(/heic|heif/gi) > -1
              ) {
                this._notificationSystem.addNotification({
                  message: window.location.hostname.endsWith(".com")
                    ? en["notify.upload.E_UNSUPPORTED_HIEF"]
                    : ru["notify.upload.E_UNSUPPORTED_HIEF"],
                  level: "error",
                });
              } else {
                this._notificationSystem.addNotification({
                  message: window.location.hostname.endsWith(".com")
                    ? en["notify.upload.E_UNSUPPORTED_FORMAT"]
                    : ru["notify.upload.E_UNSUPPORTED_FORMAT"],
                  level: "error",
                });
              }
            }}
          >
            {this.showDropzonePreview(this.props.id)}
          </Dropzone>

          {!this.dropZones[this.props.id] || (
            <button
              id={`__ROTATE_BUTTON__-${this.props.id}`}
              style={{
                position: "absolute",
                right: "-1px",
                top: "-1px",
                width: "32px",
                height: "32px",
                backgroundColor: "rgba(255,255,255,0.75)",
                backgroundImage: `url(${rotateLeft})`,
                cursor: "pointer",
                border: "none",
              }}
              onClick={() =>
                this.rotate(this.props.id, (this.dropZones[this.props.id].rotation + 3) % 4)
              }
            />
          )}

          {!this.dropZones[this.props.id] || (
            <button
              id={`__ROTATE_BUTTON__-${this.props.id}`}
              style={{
                position: "absolute",
                left: "-1px",
                top: "-1px",
                width: "32px",
                height: "32px",
                backgroundColor: "rgba(255,255,255,0.75)",
                backgroundImage: `url(${rotateRight})`,
                cursor: "pointer",
                border: "none"
              }}
              onClick={() => this.rotate(this.props.id, ++this.dropZones[this.props.id].rotation)}
            />
          )}

          {!this.dropZones[this.props.id] || (
            <div
              style={{
                position: "absolute",
                left: "-1px",
                bottom: "-1px",
                width: "calc(100% + 2px)",
                height: "5px",
              }}
            >
              <div
                id={`__PROGRESSBAR__-${this.props.id}`}
                style={{
                  position: "absolute",
                  width: "0",
                  height: "100%",
                  backgroundColor: "#00858A",
                  transition: "width 0.1s",
                }}
              />
            </div>
          )}

          <NotificationSystem ref={(notificationSystem) => this._notificationSystem = notificationSystem} />
        </div>
      );

    } else {
      return this.showFileInputThumbnail();
    }
  }
}

const connector = connect(mapStateToProps,mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(FileUpload);
