import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { change, getFormValues } from "redux-form";

import { courseSelectedAction, selectCourseAction } from "~/actions/get_course_installment";
import { addInstructions, removeInstructions } from "~/actions/patient_new_instructions";
import type { RxType } from "~/common/constants";
import { Case, is3DPROCourse, isCBCTCourse, isRetainersCourse } from "~/common/courses";
import remoteLog from "~/common/logging";
import { ARCH_BOTH } from "~/common/prescription";
import { canOrderTestPlastic } from "~/common/user";
import { deployedRussia } from "~/common/utils";
import { QuestionTooltip } from "~/components/ui/tooltip";
import type { RootState } from "~/store";

import ImpressionScanOptions from "../impression_scan_options";
import { TestPlaticOptions } from "../test_plastic_options";

const smile_link = "/api/v1/manuals/document/2"
const predict_link = "/api/v1/manuals/document/51"

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  lang: state.intl,
  instructions: state.instructions,
  courseInstallment: state.courseInstallment,
  formValues: getFormValues("correction")(state)
});

const mapDispatchToProps = {
  addInstructions,
  removeInstructions,
  selectCourseAction,
  courseSelected: courseSelectedAction,
  change,
};

type PatientNewInstructionsCourseProps = PropsFromRedux & {
  intl: IntlShape;
  rxTypeId: RxType;
  setDirty(key: string, data: unknown): void;
};

class PatientNewInstructionsCourse extends Component<PatientNewInstructionsCourseProps> {
  constructor(props: PatientNewInstructionsCourseProps) {
    super(props);
    this.selectCourse = this.selectCourse.bind(this);
    this.addCondition = this.addCondition.bind(this);
    this.selectDeepCBCTAnalysis = this.selectDeepCBCTAnalysis.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_new_instructions_course");
  }

  selectCourse(course_id: Case) {
    const coursePaymentOptions = this.props.courseInstallment[course_id] ?? [];

    if (this.props.instructions && this.props.instructions.course_id !== course_id) {
      if (!coursePaymentOptions.includes(this.props.instructions.payment_option_id)) {
        this.props.addInstructions({ payment_option_id: null });
      }
    }

    if (this.props.instructions && course_id == Case.RETAINERS) {
      this.props.removeInstructions({ sber_credit: false });
    }

    this.props.addInstructions({ course_id });
    this.props.addInstructions({ deep_cbct: null });

    this.props.setDirty(course_id);
    this.props.selectCourseAction(coursePaymentOptions);
    this.props.courseSelected(true);

    const correctionFormValues = this.props.formValues;

    if (
      correctionFormValues &&
      correctionFormValues.arch &&
      correctionFormValues.arch == ARCH_BOTH
    ) {
      if (course_id == Case.SINGLE_ARCH || course_id == Case.SUPER_SHORT) {
        this.props.change("correction", "arch", null);
      }
    }
  }

  selectDeepCBCTAnalysis(data: boolean) {
    this.props.addInstructions({ "deep_cbct": data });
  }

  addCondition(data: string) {
    this.props.addInstructions({ "condition": data });
    this.props.setDirty("condition", data);
  }

  render() {
    const locale = this.props.lang.locale;
    const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id });

    const course_id = this.props.instructions.course_id || null;
    const show_cbct_option = !isCBCTCourse(course_id) && !isRetainersCourse(course_id);

    return (
      <div id="course-section" style={{ marginTop: "20px" }}>
        <div className="form-group">
          <span className="control-label" id="validation-course_id" style={{ fontWeight: "900", fontSize: "17px", marginBottom: "10px", display: "inline-block" }}>
            <FormattedMessage id="HEADER_INSTRUCTIONS" />
            <span style={{ marginRight: "4px" }} className="required" aria-required="true">*</span>
            <span className="prescription_tooltip_show">
              <i style={{fontSize: "14px"}} className="icon-question" />
              <span className="d-inline-block prescription_tooltip">
                <FormattedMessage id="course.type.tooltip" />
                <a href={(deployedRussia() && window.location.hostname.endsWith(".ru") ? smile_link : predict_link)} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage id="tooltip.chart" />
                </a>
                <br />
                <FormattedMessage id="course.type.tooltip.sec" />
              </span>
            </span>
          </span>

              <div className="radio-list">

                <label id="course-label-5">
                  <div className="radio">
                    <input
                      style={{ marginTop: "10px" }}
                      type="radio" id="course-value-5"
                      name="course_id"
                      defaultValue={Case.CBCT}
                      onChange={() => this.selectCourse(Case.CBCT)}
                    />
                  </div>
                  <div className="course_title">
                    <img src="/img/deepcbct.png" alt="cbct-icon" style={{ width: "80px", height: "42px" }} />
                    <br />
                    <FormattedMessage id="C_UNLIMITED_V2" /><FormattedMessage id="C_UNLIMITED_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-1">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-1"
                      name="course_id"
                      defaultValue={Case.FULL}
                      onChange={() => this.selectCourse(Case.FULL)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.FULL ? "boldText" : undefined}>
                      <FormattedMessage id="C_FULL_V2" />
                    </span>
                    <FormattedMessage id="C_FULL_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-10">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-10"
                      name="course_id"
                      defaultValue={Case.TEEN}
                      onChange={() => this.selectCourse(Case.TEEN)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.TEEN ? "boldText" : undefined}>
                      <FormattedMessage id="C_TEEN_V2" />
                    </span>
                    <FormattedMessage id="C_TEEN_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-2">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-2"
                      name="course_id"
                      defaultValue={Case.SHORT}
                      onChange={() => this.selectCourse(Case.SHORT)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.SHORT ? "boldText" : undefined}>
                      <FormattedMessage id="C_SHORT_V2" />
                    </span>
                    <FormattedMessage id="C_SHORT_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-3">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-3"
                      name="course_id"
                      defaultValue={Case.SINGLE_ARCH}
                      onChange={() => this.selectCourse(Case.SINGLE_ARCH)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.SINGLE_ARCH ? "boldText" : undefined}>
                      <FormattedMessage id="C_ONE_JAW_V2" />
                    </span>
                    <FormattedMessage id="C_ONE_JAW_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-4">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-4"
                      name="course_id"
                      defaultValue={Case.SUPER_SHORT}
                      onChange={() => this.selectCourse(Case.SUPER_SHORT)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.SUPER_SHORT ? "boldText" : undefined}>
                      <FormattedMessage id="C_SUPER_SHORT_V2" />
                    </span>
                    <FormattedMessage id="C_SUPER_SHORT_COMMENT_V2" />
                  </div>
                </label>

                <label id="course-label-6">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-6"
                      name="course_id"
                      defaultValue={Case.PLAN_3D}
                      onChange={() => this.selectCourse(Case.PLAN_3D)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.PLAN_3D ? "boldText" : undefined}>
                      <FormattedMessage id="C_3D_PLAN_V2" />
                    </span>
                  </div>
                </label>

                <label id="course-label-7">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-7"
                      name="course_id"
                      defaultValue={Case.CHILDREN}
                      onChange={() => this.selectCourse(Case.CHILDREN)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.CHILDREN ? "boldText" : undefined}>
                      <NewBadge />
                      <FormattedMessage id="C_CHILDREN_V3" />
                    </span>
                    <FormattedMessage id="C_CHILDREN_COMMENT_V3" />
                    <ChildrenV3Tooltip />
                  </div>
                </label>

                <label id="course-label-11">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-11"
                      name="course_id"
                      defaultValue={Case.CHILDREN_SHORT}
                      onChange={() => this.selectCourse(Case.CHILDREN_SHORT)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.CHILDREN_SHORT ? "boldText": undefined}>
                      <NewBadge />
                      <FormattedMessage id="C_CHILDREN_SHORT_V1" />
                    </span>
                    <FormattedMessage id="C_CHILDREN_SHORT_COMMENT_V1" />
                    <ChildrenShortTooltip />
                  </div>
                </label>

                <label id="course-label-8">
                  <div className="radio">
                    <input
                      type="radio"
                      id="course-value-8"
                      name="course_id"
                      defaultValue={Case.RETAINERS}
                      onChange={() => this.selectCourse(Case.RETAINERS)}
                    />
                  </div>
                  <div className="course_title">
                    <span className={course_id === Case.RETAINERS ? "boldText" : undefined}>
                      <FormattedMessage id="C_RETAINERS" />
                    </span>
                    <FormattedMessage id="C_RETAINERS_COMMENT" /></div>
                </label>
              </div>

          <AnimatePresence>
            {isCBCTCourse(course_id) || this.props.instructions.deep_cbct == true ? (
              <ScanUploadCTWarning />
            ) : null}
          </AnimatePresence>

          {deployedRussia() && locale == "ru" && this.props.instructions && is3DPROCourse(course_id)
            ? (
              <div className="col-xs-12">
                <div className="alert alert-warning" id="course-alert-warning" style={{ color: "dimgrey" }}>
                  <FormattedMessage id="3D_SMILE_PRO_WARNING" />
                </div>
              </div>
            ) : null
          }
          <div id="form_course_error" />
        </div>

        {deployedRussia() ? (
            <div className="form-group">
              <label htmlFor="patient-diagnosis-value" className="control-label" id="validation-condition" style={{ fontWeight: "900" }}>
                <FormattedMessage id="HEADER_CONDITION" />
              </label>
              <textarea
                data-matomo-mask
                className="form-control"
                id="patient-diagnosis-value"
                rows={5}
                name="diagnos"
                placeholder={fm("CONDITION_PLACEHOLDER")}
                onChange={(e) => this.addCondition(e.target.value)}
              />
            </div>
        ) : null}

        <ImpressionScanOptions />

        {canOrderTestPlastic(this.props.user) ? (
          <TestPlaticOptions
            value={this.props.instructions.test_plastic}
            onValueChange={(testPlastic) => this.props.addInstructions({ test_plastic: testPlastic })}
          />
        ) : null}

        {show_cbct_option ? (
          <div id="deep-cbct-section">
            <h5 id="deep-cbct-label" className="block" style={{ fontWeight: 900 }}>
              <FormattedMessage id="ADDITIONAL_INSTRUCTIONS" />
            </h5>

            <label
              htmlFor="deep-cbct-value-1"
              style={{ display: "flex", alignItems: "center", gap: 8 }}
            >
              <input
                id="deep-cbct-value-1"
                style={{ marginTop: 0 }}
                type="checkbox"
                name="deep_cbct"
                defaultValue={1}
                checked={this.props.instructions.deep_cbct == true}
                onChange={(e) => this.selectDeepCBCTAnalysis(e.currentTarget.checked)}
              />
              <FormattedMessage id="C_DEEP_CBCT_ANALYSIS" />
            </label>
          </div>
          ) : null}
      </div>
    )
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNewInstructionsCourse));

export function ScanUploadCTWarning() {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <div className="col-xs-12 tw-overflow-hidden">
      <motion.p className="tw-m-0" {...(shouldReduceMotion ? {} : animationProps)}>
        <span className="alert-warning tw-block tw-p-4">
          <FormattedMessage id="SCAN_UPLOAD_CT_WARNING" />
        </span>
        <span className="tw-block tw-h-5" />
      </motion.p>
    </div>
  );
}

export function NewBadge() {
  return (
    <img
      src="/img/teenNew.svg"
      alt="cbct-icon"
      style={{ width: "40px", height: "22px", marginRight: "5px" }}
    />
  );
}

export function ChildrenV3Tooltip() {
  return (
    <QuestionTooltip>
      Доступен к заказу при соблюдении у пациента следующих условий:

      <ol className="tw-mt-2 tw-list-none tw-space-y-0.5 tw-pl-2 tw-italic">
        <li>А. Должны быть прорезавшимися постоянные первые моляры</li>
        <li>Б. Не менее 2 постоянных резцов должны быть прорезавшимися не менее, чем на 2/3</li>
        <li>В. Не менее 2 зубов (III или IV или V) в одном квадранте должны быть в наличии</li>
        <li>Г. Отсутствие постоянных 7 зубов</li>
      </ol>

      <p className="tw-m-0">
        Курс включает в себя неограниченное количество элайнеров и коррекций <strong>в течение 18 месяцев</strong>.
      </p>

      <p className="tw-m-0 tw-mt-2">
        <strong>Внимание!</strong> Дубликаты не входят в стоимость курса и при необходимости
        заказываются отдельно в соответствии с действующим прайсом.
      </p>
    </QuestionTooltip>
  );
}

export function ChildrenShortTooltip() {
  return (
    <QuestionTooltip>
      Первый комплект доступен для заказа при соблюдении у пациента следующих условий:

      <ol className="tw-mt-2 tw-list-none tw-space-y-0.5 tw-pl-2 tw-italic">
        <li>А. Должны быть прорезавшимися постоянные первые моляры</li>
        <li>Б. Не менее 2 постоянных резцов должны быть прорезавшимися не менее, чем на 2/3</li>
        <li>В. Не менее 2 зубов (III или IV или V) в одном квадранте должны быть в наличии</li>
        <li>Г. Отсутствие постоянных 7 зубов</li>
      </ol>

      <p className="tw-m-0">
        Комплект включает в себя до <strong>24 КАП</strong>, которые возможно использовать
        для лечения как на одной, так и на двух челюстях.
      </p>

      <p className="tw-m-0 tw-mt-2">
        <strong>Внимание!</strong> В рамках одной заявки можно заказать не более <strong>ТРЕХ</strong>{" "}
        комплектов (стоимость каждого комплекта составляет 40 000 рублей).
      </p>

      <p className="tw-m-0 tw-mt-2">
        Второй и третий комплекты можно заказать вне зависимости от того, прорезались 7 зубы или нет,
        в течение трех лет с момента заказа первого комплекта и до достижения пациентом 13 лет.
      </p>
    </QuestionTooltip>
  );
}
