import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "./button";

export function ConfirmationDialog({
  title,
  description,
  actionText,
  onActionClick,
  children,
}: {
  title: React.ReactChild;
  description: React.ReactChild;
  actionText: React.ReactChild;
  onActionClick(closeDialog: () => void): void;
  children: React.ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);

  function closeDialog() {
    setIsOpen(false);
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>

      <DialogContent>
        <Dialog.Title className="tw-m-0 tw-text-xl tw-font-semibold">{title}</Dialog.Title>

        <Dialog.Description className="tw-m-0 tw-space-y-2 tw-leading-relaxed">
          {description}
        </Dialog.Description>

        <div className="tw-flex tw-gap-4">
          <Button className="tw-flex-1 tw-font-semibold" onClick={() => onActionClick(closeDialog)}>
            {actionText}
          </Button>

          <Dialog.Close asChild>
            <Button className="tw-flex-1 tw-font-semibold" variant="outline">
              <FormattedMessage id="events.watch_modal.cancel" />
            </Button>
          </Dialog.Close>
        </div>
      </DialogContent>
    </Dialog.Root>
  );
}

function DialogContent({ children }: { children: React.ReactNode }) {
  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={clsx(
          // NOTE: tw-z-50 is not enough, page header has a higher z index
          "tw-fixed tw-inset-0 tw-z-[100] tw-bg-black/30 tw-backdrop-blur-sm",
          "data-[state=open]:tw-animate-in data-[state=open]:tw-fade-in-0",
          "data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0",
        )}
      />

      <Dialog.Content
        className={clsx(
          "tw-fixed tw-left-1/2 tw-top-1/2 tw-z-[100] -tw-translate-x-1/2 -tw-translate-y-1/2",
          "tw-w-full tw-border tw-bg-white tw-p-6 tw-shadow-lg tw-duration-200",
          "sm:tw-max-w-xl sm:tw-rounded-lg",
          "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out",
          "data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0",
          // FIXME: these don't work for whatever reason
          "data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95",
          "data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=open]:tw-slide-in-from-left-1/2",
          "data-[state=closed]:tw-slide-out-to-top-1/2 data-[state=open]:tw-slide-in-from-top-1/2",
        )}
      >
        <div className="tw-space-y-6">{children}</div>
        <DialogClose />
      </Dialog.Content>
    </Dialog.Portal>
  );
}

export function DialogClose({ onClick }: { onClick?: React.MouseEventHandler<HTMLButtonElement> }) {
  return (
    <Dialog.Close
      onClick={onClick}
      className={clsx(
        "tw-absolute tw-right-4 tw-top-4 tw-rounded-sm",
        "tw-opacity-70 tw-transition-opacity hover:tw-opacity-100",
        "tw-h-5 tw-w-5 tw-border-0 tw-bg-transparent tw-p-0",
      )}
    >
      <Cross2Icon className="tw-h-5 tw-w-5" />
      <span className="tw-sr-only">
        <FormattedMessage id="alert.close" />
      </span>
    </Dialog.Close>
  );
}
