import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";

import newYearBauble from "~/assets/img/new-year-bauble.svg";
import { SUPPORT_PHONE_NUMBER } from "~/common/constants";
import { deployedRussia, formatPhoneNumber } from "~/common/utils";
import { RemoteLogErrorBoundary } from "~/components/common/remote-log-error-boundary";
import { useAppSelector } from "~/store";

import { ProfileMenu } from "./profile_menu";

export default function PageHeader() {
  const tour = useAppSelector((state) => state.tour);
  const banners = useAppSelector((state) => state.banners);
  const bannersHome = banners.filter((banner) => banner.align == "center");

  return (
    <div className="page-header navbar mob-navbar">
      <header id="top-navbar" className="page-header-inner container">
        <div className="tw-relative tw-float-left tw-flex tw-h-[75px] tw-items-center tw-px-3">
          <div className="tw-flex tw-items-center tw-gap-4">
            <div id="logo">
              <NavLink
                to={bannersHome.length > 0 ? "/pages/home" : "#"}
                style={tour ? { pointerEvents: "none" } : {}}
              >
                {deployedRussia() ? (
                  <img
                    src="/img/3d_smile_logo_rgb.svg"
                    alt="logo"
                    className="logo-default"
                    style={{ width: 120 }}
                  />
                ) : (
                  <img
                    src="/img/3d_predict.svg"
                    alt="logo"
                    className="logo-default"
                    style={{ width: 150 }}
                  />
                )}
              </NavLink>

              {DISPLAY_NEW_YEAR_DECORATION ? (
                <img
                  className="tw-absolute tw-right-[-70px] tw-top-[-25px] tw-z-50 desktop:tw-right-[-50px] desktop:tw-top-[-10px]"
                  src={newYearBauble}
                  alt=""
                />
              ) : null}
            </div>

            <div className="tw-flex tw-flex-col">
              <span className="tw-text-[13px]">
                <FormattedMessage id="care_support" />
              </span>
              <a
                href={`tel:${SUPPORT_PHONE_NUMBER}`}
                className="tw-text-[#34495e] tw-no-underline tw-transition-colors hover:tw-text-[#00aebe] active:tw-text-[#00aebe]"
              >
                {formatPhoneNumber(SUPPORT_PHONE_NUMBER).replaceAll("-", " ")}
              </a>
            </div>
          </div>

          <div className="menu-toggler sidebar-toggler"></div>
        </div>

        <button
          // NOTE: <PatientMenu /> uses this id to access the element and close the menu.
          id="patient-menu-toggle-btn"
          className="menu-toggler responsive-toggler"
          data-toggle="collapse"
          data-target=".navbar-collapse"
          style={{ border: "none", backgroundColor: "transparent" }}
          aria-label="Toggle navigation menu"
          aria-controls="patient-menu-sidebar"
        ></button>

        <div className="page-top">
          <RemoteLogErrorBoundary component="profile_menu" fallbackRender={null}>
            <ProfileMenu />
          </RemoteLogErrorBoundary>
        </div>
      </header>
    </div>
  );
}

function shouldDisplayNewYearDecoration(): boolean {
  const currentDate = new Date();
  const startOfWinterDate = new Date(currentDate.getFullYear(), 11, 1);
  const endOfWinterHolidays = new Date(currentDate.getFullYear(), 0, 15);

  return currentDate >= startOfWinterDate || currentDate < endOfWinterHolidays;
}

const DISPLAY_NEW_YEAR_DECORATION = shouldDisplayNewYearDecoration();
