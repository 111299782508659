import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { change, formValueSelector, getFormValues } from "redux-form";

import { sendComment } from "../../actions/comments";
import { eraseStateProp } from "../../actions/dashboard";
import { getPatientId } from "../../actions/get_patient_id";
import { addInstructions } from "../../actions/patient_new_instructions";
import { patientIsSavingSuccess, updatePatient } from "../../actions/post_patient";
import { sendPatientCorrection } from "../../actions/send_patient_correction";
import { addUserActionNotification } from "../../actions/user_notification";
import { CommentType, RxType } from "../../common/constants";
import { hasDeepCBCT } from "../../common/courses";
import { convertToJSONFullRx } from "../../common/instructions";
import remoteLog from "../../common/logging";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import Loader from "../common/loadingInProgress";
import { scrollTo } from "../common/ScrollToElement/scrollTo";
import LinksCT from "../patient/addLinks";
import ImpressionScanOptions from "../patient/impression_scan_options";
import PatientUpdateInstructionsNewMedia from "../patient/patient_update/patient_update_instructions_new_media";
import PatientUpdateInstructionsUploadCt from "../patient/patient_update/patient_update_instructions_upload_ct";
import RecipeFormPredict from "../patient/predict_recipe_redux_form";
import RecipeFormShortRX from "../patient/recipe_redux_form_short_rx";
import PlanCorrectionPhoto from "./3d_plan_correction_photo";
import CorrectionWithCTRadioGroup from "./3d_plan_correction_with_ct";

const requiredFields = [
  'validation-stage',
  'validation-comment',
  'validation-material',
  'photo-protocol-block',
  'validation-arch',
  'validation-vertical_overlap_comment',
  'validation-midline'
];

const ScrollToField = (toId: string) => {
  scrollTo({ id: toId, duration: 1500 });
};

const mapStateToProps = (state: RootState) => ({
    user: state.user,
    patient: state.patient,
    comments: state.comments,
    media: state.media,
    media_s3: state.media_s3,
    instructions: state.instructions,
    formValues: getFormValues('correction')(state),
    links: getFormValues('links')(state),
    patientSaving: state.patientSaving
});

const mapDispatchToProps = (dispatch) => {
  return {
    sendComment: (patient_id, comment) => dispatch(sendComment(patient_id, comment, CommentType.CORRECTION)),
    eraseStateProp: (props) => dispatch(eraseStateProp(props)),
    getPatient: (patient_id) => dispatch(getPatientId(patient_id)),
    addUserActionNotification: (json) => dispatch(addUserActionNotification(json)),
    addInstruction: (data) => dispatch(addInstructions(data)),
    sendPatientCorrection: (patient_id, media_info, s3_media, links, stage, prescription, correction_with_ct) => dispatch(sendPatientCorrection(patient_id, media_info, s3_media, links, stage, prescription, correction_with_ct)),
    updatePatient: (...args) => dispatch(updatePatient(...args)),
    change: (form, field, value) => dispatch(change(form, field, value)),
    patientIsSaving: (bool) => dispatch(patientIsSavingSuccess(bool))
  }
}

type PlanCancelProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{ patient_id: string }>;

type PlanCancelState = {
  movment_error: boolean;
  confirmation: number;
  correction_length: number | false;
  showLoader: boolean;
  easy_validate: boolean;
  plan_stage: string;
  clicked: boolean;
};


class PlanCancel extends Component<PlanCancelProps, PlanCancelState> {
  constructor(props: PlanCancelProps) {
    super(props);
    this.state = {
      movment_error: false,
      confirmation: 0,
      correction_length: false,
      showLoader: false,
      easy_validate: false,
      plan_stage: '',
      clicked: false,
    }
    this.submitCorrection = this.submitCorrection.bind(this);
    this.saveCorrection = this.saveCorrection.bind(this);
    this.addComment = this.addComment.bind(this);
    this.validateStateValue = this.validateStateValue.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, '3d_plan_correction_page_predict');
  }

  componentDidMount() {
    const { patient_id } = this.props.match.params;
    this.props.getPatient(patient_id);
    this.props.eraseStateProp("media");

    if (this.props.user && this.props.user.privileges && this.props.user.privileges.easy_correction_form) {
      this.setState({ easy_validate: true });
    }

    setDocumentTitle(this.props.intl.formatMessage({ id: "treatment.correct.header" }));
  }

  UNSAFE_componentWillReceiveProps(nextProps: PlanCancelProps) {
    const { patient } = nextProps;
    if (patient && patient.course && patient.course.correction.slice(-1)[0].order_options.can_order_correction === false) {
      this.props.history.push('/pages/patients/');
    }
    if (this.state.correction_length == false) {
      if (nextProps.patient && nextProps.patient.course) {
        this.setState({ 'correction_length': nextProps.patient.course.correction.length })
      }
    }

    if (nextProps.patient) {
      if (nextProps.patient.course && this.state.correction_length) {
        if (nextProps.patient.course.correction.length > this.state.correction_length && nextProps.patient.patient_id) {
          this.props.history.push('/pages/patients/');
        }
      }
    }

    if (nextProps.user && nextProps.user.privileges && nextProps.user.privileges.easy_correction_form) {
      this.setState({ easy_validate: true })
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.patientSaving === true) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp('patient');
      this.props.history.push('/pages/patients');
    }
  }

  componentDidUpdate(prevProps) {
    const { patient } = this.props;
    if (prevProps.patient && !Object.keys(prevProps.patient).length && this.props.patient && this.props.patient.course) {
      const plan_stage = patient.course.correction.slice(-1)[0].steps_count_completed;
      if (this.state.plan_stage === 0 || !this.state.plan_stage) {
        if (this.state.plan_stage !== plan_stage && plan_stage > 0) {
          this.setState({ 'plan_stage': plan_stage });
        }
      }
    } else {
      if (prevProps.patient && Object.keys(prevProps.patient).length > 0 && prevProps.patient.course && prevProps.patient.course.correction) {
        if (this.state.plan_stage === 0 || !this.state.plan_stage) {
          const plan_stage = prevProps.patient.course.correction.slice(-1)[0].steps_count_completed;
          if (this.state.plan_stage != plan_stage && plan_stage > 0) {
            this.setState({ 'plan_stage': plan_stage });
          }
        }
      }
    }
  }

  validateStateValue(e) {
    let stepsTotal = 0;
    if (this.props.patient && this.props.patient.course && this.props.patient.course.correction) {
      stepsTotal = this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_total;
    }
    if (e.target.value <= 0 || e.target.value > stepsTotal) {
      e.target.value = null
      this.setState({ 'plan_stage': null });
    }
    if (e.target.value !== '') {
      this.setState({ 'plan_stage': parseInt(e.target.value) });
    }
  }

  addComment(data) {
    this.props.change('correction', 'comment', data);
  }

  saveCorrection() {
    this.setState({ clicked: true });
    const emptyFields = [];
    const rxTypeId = this.props.patient.rx_type_id;
    if (
      ((!['stage'].filter((elm) => {
        const cond = (this.state.plan_stage || this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_completed);
        !cond ? emptyFields.push('validation-stage') : null;
        $(`#validation-${elm}`).parent().find('label').css({ color: !cond ? 'red' : '#34495e' });
        return !cond;
      }).length
      *
      !['midline'].filter((elm) => {
        let cond = true;
        if (rxTypeId === RxType.STANDARD) {
          cond = ((this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '' && this.props.formValues[elm] !== null));
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }
      }).length
      *
      !['comment'].filter((elm) => {
        let cond = true;
          cond = ((this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '' && this.props.formValues[elm] !== null));
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
      }).length
      *
       !['arch'].filter((elm) => {
      const cond = (this.props.formValues && this.props.formValues.hasOwnProperty(elm) && !isNaN(this.props.formValues[elm]) && this.props.formValues[elm] !== null);
      !cond ? emptyFields.push(`validation-${elm}`) : null;
      $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
      return !cond;
    }).length
      *
    !['material'].filter((elm) => {
      const cond = (this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== null);
      !cond ? emptyFields.push(`validation-${elm}`) : null;
      $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
      return !cond;
    }).length
      ))) {
      const { patient_id } = this.props.match.params;
      const rxTypeId = this.props.patient.rx_type_id;
      const links = [];
      const linksArray = this.props.links && this.props.links.links ? this.props.links.links : [];
      linksArray.forEach(el => links.push(...Object.values(el)));
      const media_info = this.props.media;
      const s3_media = this.props.media_s3;
      const prescription = convertToJSONFullRx(this.props.formValues);
      prescription.rx_type_id = rxTypeId;
      const stage = this.state.plan_stage || this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_completed;
      this.props.sendPatientCorrection(patient_id, media_info, s3_media, links, stage, prescription, this.props.correction_with_ct);
    } else {
      const scrollToFields = [];
      requiredFields.forEach(f => emptyFields.forEach(a => a == f ? scrollToFields.push(f) : null))
      ScrollToField(scrollToFields[0])
    }
  }

  submitCorrection() {
    this.setState({ clicked: true });
    const emptyFields = [];
    const rxTypeId = this.props.patient.rx_type_id;
    if (
      ((!['stage'].filter((elm) => {
        const cond = (this.state.plan_stage || this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_completed);
        !cond ? emptyFields.push('validation-stage') : null;
        $(`#validation-${elm}`).parent().find('label').css({ color: !cond ? 'red' : '#34495e' });
        return !cond;
      }).length
      *
      !['midline'].filter((elm) => {
        let cond = true;
        if (rxTypeId === RxType.STANDARD) {
          cond = ((this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '' && this.props.formValues[elm] !== null));
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
        }
      }).length
      *
      !['comment'].filter((elm) => {
        let cond = true;
          cond = ((this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== '' && this.props.formValues[elm] !== null));
          !cond ? emptyFields.push(`validation-${elm}`) : null;
          $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
          return !cond;
      }).length
      *
       !['arch'].filter((elm) => {
      const cond = (this.props.formValues && this.props.formValues.hasOwnProperty(elm) && !isNaN(this.props.formValues[elm]) && this.props.formValues[elm] !== null);
      !cond ? emptyFields.push(`validation-${elm}`) : null;
      $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
      return !cond;
    }).length
      *
    !['material'].filter((elm) => {
      const cond = (this.props.formValues && this.props.formValues.hasOwnProperty(elm) && this.props.formValues[elm] !== null);
      !cond ? emptyFields.push(`validation-${elm}`) : null;
      $(`#validation-${elm}`).css({ color: (!cond) ? 'red' : '#34495e' });
      return !cond;
    }).length
    *
    !['profile', 'front_view', 'full_face_with_smile', 'full_face_without_smile', 'lateral_view_left', 'lateral_view_right', 'occlusal_view_lower', 'occlusal_view_upper'].filter((elm) => {
      const cond = ((this.props.media[elm] && this.props.media[elm].md5) || !!(((this.props || {}).patient || {}).media || {})[elm]);
      !cond ? emptyFields.push('photo-protocol-block') : null;
      $(`#${elm}`).parent().prev().prev().css({ color: !cond ? 'red' : '#34495e' });
      return !cond;
    }).length
      ))) {
      const { patient_id } = this.props.match.params;
      const rxTypeId = this.props.patient.rx_type_id;
      const links = [];
      const linksArray = this.props.links && this.props.links.links ? this.props.links.links : [];
      linksArray.forEach(el => links.push(...Object.values(el)));
      const media_info = this.props.media;
      const s3_media = this.props.media_s3;
      const prescription = convertToJSONFullRx(this.props.formValues);
      prescription.rx_type_id = rxTypeId;
      const stage = this.state.plan_stage || this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_completed;
      this.props.sendPatientCorrection(patient_id, media_info, s3_media, links, stage, prescription, this.props.correction_with_ct);
    } else {
      const scrollToFields = [];
      requiredFields.forEach(f => emptyFields.forEach(a => a == f ? scrollToFields.push(f) : null))
      ScrollToField(scrollToFields[0])
    }
  }

  render() {
    const { patient } = this.props;
    const patientIsLoading = !patient.patient_id;

    if (patientIsLoading) {
      return <Loader />;
    }

    const rxTypeId = patient.rx_type_id;
    const correction_num = patient.course ? patient.course.correction.length : undefined;

    let stepsTotal = 0;
    let stepsCompleted = 0;

    if (patient.course && patient.course.correction) {
      const lastCorrection = patient.course.correction[patient.course.correction.length - 1];
      stepsTotal = lastCorrection.steps_count_total;
      stepsCompleted = this.state.plan_stage || lastCorrection.steps_count_completed != 0
        ? lastCorrection.steps_count_completed
        : null
    }

    const { course_id } = patient.course;
    const { material, arch, vertical_overlap, vertical_overlap_comment, midline } = this.props;
    const isDeepCBCT = hasDeepCBCT(patient);

    return (
      <div className="portlet light bordered" id="correction-section">
        <div className="portlet-title">
          <div className="col-md-8 col-sm-8 col-xs-8 caption">
            <i className="icon-book-open font-green" />
            <span className="caption-subject font-green bold uppercase">
              <FormattedMessage id="treatment.correct.header" />
            </span>
          </div>

          <div className="col-md-4 col-sm-4 col-xs-4" style={{ padding: "8px 0" }}>
            <span className="required mob-title" aria-required="true" style={{}}>* </span>
            <i className='mob-title'>
            - <FormattedMessage id="requiredField" />
            </i>
          </div>
        </div>

        <div className="portlet-body">
          <div className="form-group" id="correction-stage-section">
            <label
              id="validation-stage"
              className="control-label"
              style={{ fontWeight: 900 }}
              htmlFor="correction-stage-value"
              >
              <FormattedMessage id="plan.cancel.stage" />
              <span className="required" aria-required="true">*</span>
            </label>
            <br />
            <br />
            <input
              id="correction-stage-value"
              style={{ width: 80, display: 'inline' }}
              type="number"
              size={2}
              min={1}
              max={stepsTotal}
              className="form-control"
              name="stage"
              defaultValue={stepsCompleted}
              onInput={e => this.validateStateValue(e)}
            />&nbsp;
            <span><FormattedMessage id="pat_table.of" /> {stepsTotal}</span>
          </div>
          <br />

          {rxTypeId === RxType.STANDARD
            ? (
              <div className="form-group">
                <label
                  id="validation-comment"
                  className="control-label"
                  style={{ fontWeight: 900 }}
                  htmlFor="correction-extra-comment"
                >
                  <FormattedMessage id="EXTRA_COMMENTS" />
                  <span className="required" aria-required="true">*</span>
                </label>
                <textarea
                  id="correction-extra-comment"
                  data-matomo-mask
                  data-hj-suppress
                  className="form-control"
                  rows={5}
                  name="comment"
                  onChange={(e) => this.addComment(e.target.value)}
                />
              </div>
            ) : null
          }

          <ImpressionScanOptions />
          <br />
          {isDeepCBCT ? (
            <>
              <CorrectionWithCTRadioGroup />
              <br />
            </>
           ) : null}
          <PlanCorrectionPhoto correctionNumber={correction_num} />
          <PatientUpdateInstructionsNewMedia has_medit_files={false} showUploadedFiles={false} />
          <PatientUpdateInstructionsUploadCt showUploadedFiles={false} />
          <LinksCT />
          {rxTypeId === RxType.STANDARD ?  <RecipeFormPredict course_id={course_id} /> : <RecipeFormShortRX course_id={course_id} />}
          <br />
          <div>
            <button
              type="submit"
              className="btn default"
              id="save-pacient-btn"
              style={{ margin: 2 }}
              onClick={() => this.saveCorrection()}
            >
              <FormattedMessage id="BTN_SAVE_DRAFT" />
            </button>
            <button
              type="submit"
              className="btn green"
              id="submit-pacient-btn"
              style={{ margin: 2 }}
              onClick={() => this.submitCorrection()}
            >
              <FormattedMessage id="plan.cancel.btn" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const selector = formValueSelector('correction')

PlanCancel = connect(
  (state) => {
    const material = selector(state, 'material')
    const arch = selector(state, 'arch')
    const vertical_overlap = selector(state, 'vertical_overlap')
    const vertical_overlap_comment = selector(state, 'vertical_overlap_comment')
    const midline = selector(state, 'midline')
    const correction_with_ct = selector(state, "correction_with_ct");

    return {
      material,
      arch,
      vertical_overlap,
      vertical_overlap_comment,
      midline,
      correction_with_ct,
    }
  }
)(PlanCancel)

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanCancel));
