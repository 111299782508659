import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink, Redirect, type RouteComponentProps } from "react-router-dom";
import { useSpinDelay } from "spin-delay";

import { addUserActionNotification } from "../actions/user_notification";
import remoteLog from "../common/logging";
import { API_RECOVERY, API_RECOVERY_RESTORE } from "../config";
import { setDocumentTitle } from "../hooks/use-document-title";
import type { TUserActionNotification } from "../reducers/dashboard";
import type { RootState } from "../store";
import Loader from "./common/loadingInProgress";
import Logo from "./logo";
import Notification from "./notification";
import PageFooter from "./page_footer";
import { Button } from "./ui/button";
import {
  levenshteinRatio,
  MIN_PASSWORD_LENGTH,
  PASSWORD_LOGIN_MAX_RATIO,
  REG_DIGITS,
  REG_LOWER_CHARS,
  REG_SPEC_CHARS,
  REG_UPPER_CHARS,
  SPEC_CHAR_TEXT,
} from "./validation/password_validation_constants";

const mapStateToProps = (state: RootState) => ({ user: state.user });

const mapDispatchToProps = {
  addUserActionNotification,
};

const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
  message: "notify.doctor.password.failed",
  level: "error",
  position: "tc",
  autoDismiss: 5,
};

type PasswordRecoveryProps = PropsFromRedux &
  RouteComponentProps<{ uuid: string; token: string }> & { intl: IntlShape };

type PasswordRecoveryState = {
  loading: boolean;
  error: boolean;
  passwordSuccess: boolean;
  passwordError: boolean;
  new_password: string;
  new_password_repeat: string;
  disabled: boolean;
  inputHidden: boolean;
  login: string;
  password_lower_chars: boolean;
  password_upper_chars: boolean;
  password_digits: boolean;
  password_spec_chars: boolean;
  password_length: boolean;
  password_match: boolean;
  password_similar: boolean;
  isSubmitting: boolean;
};

class PasswordRecovery extends Component<PasswordRecoveryProps, PasswordRecoveryState> {
  constructor(props: PasswordRecoveryProps) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      passwordSuccess: false,
      passwordError: false,
      new_password: "",
      new_password_repeat: "",
      disabled: true,
      inputHidden: true,
      login: "",
      password_lower_chars: false,
      password_upper_chars: false,
      password_digits: false,
      password_spec_chars: false,
      password_length: false,
      password_match: false,
      password_similar: false,
      isSubmitting: false,
    };
    this.checkTokens = this.checkTokens.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.showHidePassword = this.showHidePassword.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "new_password");
  }

  componentDidMount() {
    if (this.props.match && this.props.match.params) {
      const { token, uuid } = this.props.match.params;
      this.checkTokens(token, uuid);
    }
    setDocumentTitle(this.props.intl.formatMessage({ id: "password.recovery.title" }));
  }

  validateFieldsSubmit() {
    const { new_password, new_password_repeat } = this.state;

    let password_digits = false;
    let password_upper_chars = false;
    let password_lower_chars = false;
    let password_spec_chars = false;
    let password_length = false;
    let password_similar = false;
    let password_match = false;

    if (new_password != "") {
      password_digits = REG_DIGITS.test(new_password);
      password_upper_chars = REG_UPPER_CHARS.test(new_password);
      password_lower_chars = REG_LOWER_CHARS.test(new_password);
      password_spec_chars = REG_SPEC_CHARS.test(new_password);
      password_length = new_password.length >= MIN_PASSWORD_LENGTH;
      password_match = new_password == new_password_repeat;
      password_similar =
        levenshteinRatio(this.state.login.toLowerCase(), new_password.toLowerCase()) <=
        PASSWORD_LOGIN_MAX_RATIO;
    }

    const isValid =
      password_digits &&
      password_length &&
      password_lower_chars &&
      password_spec_chars &&
      password_upper_chars &&
      password_similar &&
      password_match;

    this.setState({
      password_digits,
      password_length,
      password_lower_chars,
      password_spec_chars,
      password_upper_chars,
      password_similar,
      password_match,
      disabled: !isValid,
    });

    return isValid;
  }

  renderCheckList() {
    const doneStyles = { color: "green", fontWeight: 700 };
    const unDoneStyles = { color: "red", fontWeight: 500 };

    const {
      password_digits,
      password_length,
      password_lower_chars,
      password_upper_chars,
      password_spec_chars,
      password_similar,
      password_match,
    } = this.state;

    return (
      <div style={{ marginBottom: "20px" }}>
        <div style={{ textAlign: "center", fontWeight: 600 }}>
          <h4>
            <FormattedMessage id="password.requirements.header" />
          </h4>
        </div>
        <br />
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_digits == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.digits" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i
            className="icon-check"
            style={password_upper_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.upper.chars" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i
            className="icon-check"
            style={password_lower_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.lower.chars" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_length == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.length" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }} title={SPEC_CHAR_TEXT}>
          <i
            className="icon-check"
            style={password_spec_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.special" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_match == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.match" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_similar == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.similar" />
        </div>
      </div>
    );
  }

  checkTokens(token: string, uuid: string) {
    if (token && uuid) {
      void fetch(API_RECOVERY, {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ token, uuid }),
      }).then((response) => {
        if (!response.ok) {
          this.setState({ loading: false, error: true });
        } else {
          this.setState({ loading: false, error: false });
          return response.json().then((data) => this.setState({ login: data.login }));
        }
      });
    }
  }

  changePassword() {
    if (this.props.match && this.props.match.params && this.validateFieldsSubmit()) {
      const { token, uuid } = this.props.match.params;
      const { new_password } = this.state;
      this.setState({ isSubmitting: true });
      void fetch(API_RECOVERY_RESTORE, {
        method: "POST",
        headers: { Accept: "application/json", "Content-Type": "application/json" },
        body: JSON.stringify({ token, uuid, password: new_password }),
      })
        .then((response) => {
          if (!response.ok) {
            this.setState({
              loading: false,
              error: false,
              passwordError: true,
              passwordSuccess: false,
            });
            this.props.addUserActionNotification(NOTIFICATION_MSG_ERROR);
          } else {
            this.setState({
              loading: false,
              error: false,
              passwordError: false,
              passwordSuccess: true,
            });
          }
        })
        .finally(() => {
          this.setState({ isSubmitting: false });
        });
    }
  }

  showHidePassword() {
    const { inputHidden } = this.state;
    this.setState({ inputHidden: !inputHidden });
  }

  render() {
    const { loading, error, passwordError, passwordSuccess, disabled, inputHidden, isSubmitting } =
      this.state;
    const { intl } = this.props;

    if (this.props.user && this.props.user.account_id) {
      return <Redirect to="/pages/patients" />;
    } else if (loading) {
      return <Loader />;
    } else {
      return (
        <div>
          <Notification />
          <Logo />
          <div className="content" style={{ paddingBottom: "15px" }}>
            {error === false && passwordError === false && passwordSuccess === true ? (
              <div>
                <span>
                  <FormattedMessage id="restore_password.success" />
                </span>
                <NavLink to="/login">
                  <span>
                    {" "}
                    <FormattedMessage id="restore_password.success_link" />
                  </span>
                </NavLink>
              </div>
            ) : null}
            {error === false && passwordSuccess !== true ? (
              <form
                className="login-form"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.changePassword();
                }}
              >
                {this.renderCheckList()}
                <div className="form-group">
                  <div className="input-icon">
                    <i className="fa fa-lock" />
                    <label
                      htmlFor="new-password-input"
                      className="control-label visible-ie8 visible-ie9"
                    >
                      <FormattedMessage id="new_password.new_password" />
                    </label>
                    <input
                      id="new-password-input"
                      className="form-control placeholder-no-fix"
                      type={inputHidden ? "password" : "text"}
                      autoComplete="off"
                      placeholder={intl.formatMessage({ id: "new_password.new_password" })}
                      name="new_password"
                      onChange={(e) =>
                        this.setState({ new_password: e.target.value }, () =>
                          this.validateFieldsSubmit(),
                        )
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor="new-password-repeat-input"
                    className="control-label visible-ie8 visible-ie9"
                  >
                    <FormattedMessage id="login.password" />
                  </label>
                  <div className="input-icon">
                    <i className="fa fa-lock" />
                    <input
                      id="new-password-repeat-input"
                      className="form-control placeholder-no-fix"
                      type={inputHidden ? "password" : "text"}
                      autoComplete="off"
                      placeholder={intl.formatMessage({ id: "new_password.confirm" })}
                      name="new_password_repeat"
                      onChange={(e) =>
                        this.setState({ new_password_repeat: e.target.value }, () =>
                          this.validateFieldsSubmit(),
                        )
                      }
                    />
                  </div>
                </div>
                <div className="tw-flex tw-items-center tw-justify-between">
                  <i
                    tabIndex={0}
                    role="checkbox"
                    aria-checked={!inputHidden}
                    aria-label={intl.formatMessage({ id: "login.show_password" })}
                    className={inputHidden ? "icon-lock" : "icon-lock-open"}
                    style={{ fontSize: "18px" }}
                    onClick={() => this.showHidePassword()}
                    onKeyDown={(event) => {
                      if (event.key == " ") {
                        this.showHidePassword();
                      }
                    }}
                  />
                  <SubmitButton disabled={disabled} isSubmitting={isSubmitting} />
                </div>
                <div style={{ padding: "0px 30px 55px 30px" }}></div>
              </form>
            ) : (
              <div>
                {error === true && passwordSuccess !== true ? (
                  <div className="portlet-body" style={{ textAlign: "center" }}>
                    <FormattedMessage id="password.recovery.invalid.link" />
                  </div>
                ) : null}
              </div>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ margin: "0 auto", textAlign: "center", borderTop: "1px solid #c2cad8" }}>
              <PageFooter />
            </div>
          </div>
        </div>
      );
    }
  }
}

function SubmitButton({ disabled, isSubmitting }: { disabled: boolean; isSubmitting: boolean }) {
  const displayLoading = useSpinDelay(isSubmitting, { delay: 0, minDuration: 300 });

  return (
    <Button
      type="submit"
      style={{ minWidth: 100 }}
      disabled={disabled || displayLoading}
    >
      <span role={displayLoading ? "status" : undefined}>
        <FormattedMessage id={displayLoading ? "loading" : "BTN_SUBMIT"} />
      </span>
    </Button>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PasswordRecovery));
