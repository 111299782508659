import * as Toggle from "@radix-ui/react-toggle";
import { Decimal } from "decimal.js";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink, type RouteComponentProps } from "react-router-dom";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";

import { getDoctorsPays, getDoctorsTasks } from "~/actions/dashboard";
import { doctorPaymentBatch } from "~/actions/invoice";
import { PAYMENT_SYSTEM_TYPE, PaymentSystem, SBERBANK_DISCLAIMER } from "~/common/constants";
import remoteLog from "~/common/logging";
import { deployedRussia, deployedUSA, isDevelopment } from "~/common/utils";
import { FormatNumber, formatPrice } from "~/components/common/FormatNumber";
import Loader from "~/components/common/loadingInProgress";
import { PersonName } from "~/components/common/PersonName";
import { Button } from "~/components/ui/button";
import { Layout } from "~/components/ui/layout";
import { PatientStatusBadge } from "~/components/ui/patient-status-badge";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { TDoctorPayment, TPatient } from "~/reducers/dashboard";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    pays: state.doctorPays,
    invoice: state.invoice,
  };
};

const mapDispatchToProps = {
  getPays: getDoctorsPays,
  getTasks: getDoctorsTasks,
  doctorPaymentBatch: doctorPaymentBatch,
};

const MenuList = props => {
    return (
        <components.MenuList {...props}>
            {props.children}
        </components.MenuList>
    );
};

const customStyles = {
    control: (styles) => ({
        ...styles,
        width: 380
    }),
    menu: (styles) => {
        return {
            ...styles,
            position: 'unset'
        };
    },
};

const animatedComponents = makeAnimated();

type PatientsPayListProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

export type PatientsPayListState = {
  pays: TDoctorPayment[];
  paymentState: boolean;
  payment_alert: boolean;
  sort: {
    order: "asc" | "desc";
    name: "patient_id";
  };
  patients: Record<TPatient["patient_id"], { course: { price: number; cbct?: boolean; } }>
  total: number | string;
  showLoader: boolean;
  payment_account: boolean;
  selectedValues: Record<number, TOption[]>;
  clicked: boolean;
};

type TOption = {
  value: number;
  price: number;
  patient_id: TPatient["patient_id"];
  label: string;
  type: "course" | "extra";
  remain?: number;
  next?: number;
  cbct?: true;
  id?: number
};

class PatientsPayList extends Component<PatientsPayListProps, PatientsPayListState> {
    constructor(props: PatientsPayListProps) {
        super(props);
        this.state = {
            pays: props.pays.patients,
            paymentState: false,
            payment_alert: false,
            sort: {
                order: 'desc',
                name: 'patient_id'
            },
            patients: {},
            total: 0,
            showLoader: false,
            payment_account: false,
            selectedValues: {},
            clicked: false
        }

        this.togglePayment = this.togglePayment.bind(this);
        this.renderPrice = this.renderPrice.bind(this);
        this.fm = this.fm.bind(this);
    }

    componentDidCatch(e: Error) {
      remoteLog(e, 'patients_pay_list');
    }

    fm(id: MessageDescriptor["id"]) {
      return this.props.intl.formatMessage({ id });
    }

    UNSAFE_componentWillReceiveProps(next_props: PatientsPayListProps) {
        if (this.props.user.account_id && !next_props.user.account_id) {
            this.props.history.push('/');
        }
        this.setState({ pays: next_props.pays.patients })

        if (deployedRussia() && this.props.intl.locale === 'ru' && (location.protocol === 'https:' || isDevelopment)) {
            if (next_props.invoice.link && next_props.invoice.link.formUrl) {
                window.location.href = next_props.invoice.link.formUrl;
            }
        }

        if (deployedUSA() && this.props.intl.locale === 'en' && location.protocol === 'https:') {
            if (next_props.invoice && next_props.invoice.link && next_props.invoice.link.token && next_props.invoice.link.payment_endpoint) {
                this.acceptHostedAction(next_props.invoice.link.payment_endpoint, next_props.invoice.link.token);
            }
        }

        let arr = {}
        for (let i = 0; i < next_props.pays.patients.length; i++) {
            arr = {...arr, [i]: []}
        }
        this.setState({selectedValues: arr})
    }

    sortedPayments() {
        if (this.state.pays.length < 2) {
            return this.state.pays;
        }

        const sort_order = this.state.sort.order === 'asc' ? 1 : -1;
        return this.state.pays.sort((p1, p2) => {
            if (this.state.sort.name === 'patient_id') {
                return sort_order * (p1.patient_id - p2.patient_id);
            } else if (this.state.sort.name === 'latest_correction') {
                const p1t = this.fm(p1.status);
                const p2t = this.fm(p2.status);
                return sort_order * (p1t.localeCompare(p2t));
            } else {
                return sort_order * p1[this.state.sort.name].localeCompare(p2[this.state.sort.name]);
            }
        })
    }

    componentDidMount() {
        const { user } = this.props;
        if (deployedRussia() && this.props.intl.locale != 'ru') {
          this.props.history.push('/pages/patients');
        }
        this.props.getPays();
        this.props.getTasks();
        window.scrollTo(0, 0)
        _paq.push(['HeatmapSessionRecording::enable']);
        if (user && user.account_id) {
            _paq.push(['setUserId', user.account_id.toString()]);
        }
        setDocumentTitle(this.props.intl.formatMessage({ id: "pat_table_tasks.block_payments" }));
    }

    togglePayment() {
      this.setState({ paymentState: !this.state.paymentState });
    }

    acceptHostedAction(path, token) {
        const form = document.createElement('form');
        form.method = "post";
        form.action = path;

        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = "token";
        hiddenField.value = token;
        form.appendChild(hiddenField);

        document.body.appendChild(form);
        form.submit();
    }

    renderPrice(payments: { paid: number; total: number; }) {
      if (!payments) {
        return null;
      }

      return (
        <>
          <FormatNumber value={payments.paid} /> {this.fm("pat_table.of")} <FormatNumber value={payments.total} />
        </>
      );
    }

    reformatPaymentsArray(
      payments: TDoctorPayment["payments"],
      patient_id: TPatient["patient_id"],
      course: TDoctorPayment["course"],
      course_option_tag: TDoctorPayment["course_option_tag"]
    ) {
        const {next, remain, extra, cbct} = payments;
        const mainOptions: TOption[] = [];
        const extraOptions: TOption[] = [];
        if (extra) {
            extra.map(x => {
                extraOptions.push({
                    value: x.id,
                    label: `${this.fm(x.tag)} ${formatPrice(x.price)}${this.fm('pat.block.payment.currency.curr')}`,
                    price: x.price,
                    patient_id,
                    id: x.id,
                    type: 'extra'
                })
            })
        }
        if (deployedUSA() && this.props.intl.locale === 'en') {
            if (remain !== 0) {
                mainOptions.push({
                    value: remain,
                    label: `${this.fm(`${course}_USA`)} ${this.fm('pat.block.payment.currency.curr')} ${formatPrice(remain)}`,
                    patient_id,
                    remain,
                    type: 'course',
                    price: remain
                })
            }
        } else if (deployedRussia() && this.props.intl.locale === 'ru') {
            if (next > 0 && remain > 0 && next !== remain) {
                mainOptions.push({
                        value: next,
                        label: `${this.fm(course)} ${formatPrice(next)} ${this.fm('pat.block.payment.currency.curr')}`,
                        next,
                        type: 'course',
                        patient_id,
                        price: next
                    },
                    {
                        value: remain,
                        label: `${this.fm('pat.block.payment.pay.all')} ${formatPrice(remain)} ${this.fm('pat.block.payment.currency.curr')}`,
                        remain,
                        type: 'course',
                        patient_id,
                        price: remain
                    })
            } else if (next === remain && next > 0 && remain > 0) {
                // We introduce a new version of the children course, and it has a different name
                // from the previous one.
                const label = this.fm(course_option_tag == "CHILDREN_V3" ? `C_${course_option_tag}` : course);
                mainOptions.push({
                    value: next,
                    label: `${label} ${formatPrice(next)} ${this.fm('pat.block.payment.currency.curr')}`,
                    next,
                    type: 'course',
                    patient_id,
                    price: next
                })
            }
        }

        if (cbct) {
            mainOptions.map(x => {
                x.cbct = true
            })
        }

        return [
            {
                label: this.fm('pat.block.payment.payments'),
                options: mainOptions
            },
            {
                label: this.fm('pat.block.payment.extras'),
                options: extraOptions
            }
        ]
    }

    formatPayObject(e: TOption[], pid: TPatient["patient_id"], i: number) {
        this.filterSelect(e, i)
        const patientsObj = {};
        const id = pid;
        e.map(x => {
            const {cbct} = x;
            if (x.remain && x.type === 'course') {
                patientsObj[x.patient_id] = {...patientsObj[x.patient_id], ...{'course': {price: x.remain}}}
            } else if (x.next && x.type === 'course' && !patientsObj[x.patient_id]?.course?.price) {
                patientsObj[x.patient_id] = {...patientsObj[x.patient_id], ...{'course': {price: x.next}}}
            }


            if (x.cbct) {
                patientsObj[x.patient_id] = {
                    ...patientsObj[x.patient_id], ...{
                        'course': {
                            ...patientsObj[x.patient_id].course,
                            cbct
                        }
                    }
                }

            }

            if (patientsObj[x.patient_id]?.extra && x.type === 'extra') {
                patientsObj[x.patient_id] ['extra'].push({
                    price: x.price,
                    id: x.id
                })
            } else if (x.type && x.type === 'extra') {
                patientsObj[x.patient_id] = {
                    ...patientsObj[x.patient_id], ...{
                        'extra': [{
                            price: x.price,
                            id: x.id
                        }]
                    }
                }
            }
        })
        if (e.length === 0) {
            delete this.state.patients[id]
        }
        this.setState({patients: {...this.state.patients, ...patientsObj}})
        setTimeout(() => {
            this.setTotal();
        })
    }

    filterSelect(e: TOption[], i: number) {
        let obj = {};
        let valuesArr;
        e.forEach(x => {
            if (x.remain) {
                obj = {...obj, remain: x.remain}
            } else if (x.next) {
                obj = {...obj, next: x.next}
            }
        })

        if (obj.remain && !obj.next) {
            valuesArr = e.filter(x => x.remain || x.type === 'extra')
        } else if (obj.next && !obj.remain) {
            valuesArr = e.filter(x => x.next || x.type === 'extra')
        } else {
            valuesArr = e.filter(x => x.type === 'extra')
        }

        let objectArr = this.state.selectedValues
        objectArr[i] = valuesArr

        setTimeout(() => {
            this.setState({selectedValues: {...this.state.selectedValues, ...objectArr}})

        })
    }

    setPatientTotal(id) {
        let pricesArr = [];
        let coursePrice = 0;
        let total;

        if (this.state.patients[id] && this.state.patients[id].course) {
            coursePrice = this.state.patients[id].course.price
        }

        if (this.state.patients[id] && this.state.patients[id].extra) {
            pricesArr = this.state.patients[id].extra.map(x => x.price)
        }

        pricesArr.push(coursePrice)
        total = pricesArr.reduce((acc, curr) => new Decimal(acc).plus(new Decimal(curr))).toString()

        return formatPrice(parseFloat(total));
    }

    setTotal() {
        let patientsArr = Object.values(this.state.patients)
        let coursePrices = [];
        let extraPrices = [];
        let finalArr;
        let total = 0;
        if (patientsArr.length === 0) {
            this.setState({total})
        } else {
            patientsArr.forEach(x => {
                if (x?.course) {
                    coursePrices.push(x.course.price)
                }
                if (x?.extra) {
                    x.extra.map(x => {
                        extraPrices.push(x.price)
                    })

                }
            })

            finalArr = coursePrices.concat(extraPrices)

            if (finalArr.length > 0) {
                total = finalArr.reduce((x, y) => new Decimal(x).plus(new Decimal(y))).toString()
            }
            this.setState({total: total})
        }
    }

    submitPayment() {
      this.setState({ showLoader: true });
      this.setState({ clicked: true });
      this.props.doctorPaymentBatch(this.state.patients, this.state.total, this.state.payment_account);
    }

    onPaymentAccountChecked() {
      if (!this.state.payment_account) {
        this.setState({ payment_account: true });
      } else {
        this.setState({ payment_account: false });
      }
    }

    render() {
      const isLoading = !this.props.pays.hasBeenLoaded;
      const showPaymentsPage = window.location.protocol == "https:" || isDevelopment;

      if (!showPaymentsPage) {
        return null;
      }

      return (
        <Layout>
          <Portlet as="main">
            <PortletTitle iconClassName="icon-wallet" id="patients-table-block-title">
              <FormattedMessage id="pat_table_tasks.block_payments" />
            </PortletTitle>

            <div className="portlet-body">
              <div id="doctor_pacients_table_wrapper" className="dataTables_wrapper no-footer">
                <div className="row">
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <Toggle.Root asChild>
                      <Button
                        id="test-btn"
                        variant={this.state.payment_alert ? "danger" : "default"}
                        // NOTE: minWidth is set to avoid the button size change on toggle because of the text change
                        style={{ marginBottom: 13, minWidth: 152 }}
                        onClick={this.togglePayment}
                        disabled={isLoading}
                      >
                        <FormattedMessage
                          id={this.state.paymentState ? "general.payment.close" : "general.payment.show"}
                        />
                      </Button>
                    </Toggle.Root>
                  </div>
                </div>

                <div className="table-scrollable table-responsive">
                  <table
                    className="table table-bordered table-hover dataTable no-footer"
                    id="doctor_pacients_table"
                    role="grid"
                  >
                    <thead id="patients-table-head">
                      <tr role="row">
                        <th style={{ width: 14 }}>#</th>
                        <th style={{ width: 110 }}>
                          <FormattedMessage id="pat_table.name" />
                        </th>
                        <th style={{ width: 80 }}>
                          <FormattedMessage id="pat_table.payments" />
                        </th>
                        <th style={{ width: 100 }}>
                          <FormattedMessage id="pat_table.status" />
                        </th>
                        <th style={{ width: 300 }}>
                          <FormattedMessage id="pat_table.services" />
                        </th>
                        <th style={{ width: 60 }}>
                          <FormattedMessage id="pat_table.total" />
                        </th>
                      </tr>
                    </thead>

                    <tbody id="patients-table-body">
                      {isLoading ? (
                        <tr className="clickable-row even" role="row">
                          <td colSpan={100} style={{ padding: 0 }}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        this.sortedPayments().map((patient, i) => {
                          return (
                            <tr
                              key={"p" + i}
                              className={"clickable-row " + (i % 2 ? "even" : "odd")}
                              role="row"
                            >
                              <td className="sorting_1">{patient.patient_id}</td>
                              <td>
                                <NavLink to={`/pages/patient/${patient.patient_id}`}>
                                  <PersonName person={patient} />
                                </NavLink>
                              </td>
                              <td>
                                {this.state.paymentState ? this.renderPrice(patient.total_payments) : null}
                              </td>
                              <td>
                                <PatientStatusBadge status={patient.status} />
                              </td>
                              <td data-matomo-mask data-hj-suppress>
                                <Select
                                  styles={customStyles}
                                  closeMenuOnSelect={false}
                                  components={{ animatedComponents, MenuList }}
                                  isMulti
                                  options={this.reformatPaymentsArray(
                                    patient.payments,
                                    patient.patient_id,
                                    patient.course,
                                    patient.course_option_tag
                                  )}
                                  onChange={(e) => this.formatPayObject(e, patient.patient_id, i)}
                                  placeholder={this.fm("select.option")}
                                  value={this.state.selectedValues[i]}
                                />
                              </td>
                              <td data-matomo-mask data-hj-suppress>
                                {this.setPatientTotal(patient.patient_id)}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>

                <br />
                {deployedUSA() ? (
                  <div>
                    <b>
                      <FormattedMessage id="payments.doctor.total" />
                    </b>{" "}
                    <FormattedMessage id="pat.block.payment.currency.curr" />{" "}
                    {formatPrice(parseFloat(this.state.total))}
                  </div>
                ) : (
                  <div>
                    <b>
                      <FormattedMessage id="payments.doctor.total" />
                    </b>{" "}
                    {formatPrice(parseFloat(this.state.total))}&nbsp;
                    <FormattedMessage id="pat.block.payment.currency.curr" />
                  </div>
                )}

                <br />
                {deployedUSA() && !this.props.user.payment_account ? (
                  <div className="checkbox" style={{ padding: "0px 0 0px 20px", margin: "0 0 0 0" }}>
                    <input
                      type="checkbox"
                      id="payment_account"
                      name="payment_account"
                      value="payment_account"
                      checked={this.state.payment_account}
                      onClick={() => this.onPaymentAccountChecked()}
                    />
                    <label htmlFor="payment_account">Save my payment information</label>
                  </div>
                ) : null}

                {deployedRussia() && PAYMENT_SYSTEM_TYPE == PaymentSystem.SBERBANK && this.props.intl.locale == "ru" ? (
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows={8}
                      readOnly={true}
                      defaultValue={SBERBANK_DISCLAIMER}
                    ></textarea>
                  </div>
                ) : null}

                <br />
                <div>
                  <Button
                    variant="blue"
                    disabled={this.state.total <= 0 || this.state.clicked}
                    onClick={() => this.submitPayment()}
                  >
                    <FormattedMessage id="pat.payments.buttons.pay" />
                  </Button>
                </div>

                {this.state.showLoader ? (
                  <Loader msg={this.fm("pat.payments.you.will.be.redirected")} />
                ) : null}
              </div>
            </div>
          </Portlet>
        </Layout>
      );
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientsPayList));
