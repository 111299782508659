import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, type ConnectedProps } from 'react-redux';

import { sendPatientPaymentRequest } from '~/actions/invoice';
import { PAYMENT_SYSTEM_TYPE, PaymentSystem, SBERBANK_DISCLAIMER } from '~/common/constants';
import Loader from '~/components/common/loadingInProgress';
import { Button } from '~/components/ui/button';
import { Portlet, PortletTitle } from '~/components/ui/portlet';
import { setDocumentTitle } from '~/hooks/use-document-title';
import type { TPatientData } from '~/reducers/dashboard';

const mapDispatchToProps = {
  sendPatientPaymentRequest,
};

type PaymentInvoiceProps = PropsFromRedux & { patient: Partial<TPatientData>; token: string };

type PaymentInvoiceState = {
  amount: null;
  canSubmit: boolean;
  showLoader: boolean;
};

class PaymentInvoice extends Component<PaymentInvoiceProps, PaymentInvoiceState> {
  constructor(props: PaymentInvoiceProps) {
    super(props);
    this.state = {
      amount: null,
      canSubmit: false,
      showLoader: false,
    }
    this.requestToPay = this.requestToPay.bind(this);
    this.paymentOptions = this.paymentOptions.bind(this);
    this.setAmount = this.setAmount.bind(this);
  }

  componentDidUpdate() {
    const { patient } = this.props;
    if (patient && patient.invoice && patient.invoice.formUrl) {
      window.location.href = patient.invoice.formUrl
    }
  }

  componentDidMount() {
    setDocumentTitle("Он-лайн оплата");
  }

  /*
  componentDidMount() {
    if (this.props && this.props.patient && this.props.patient.course) {
      const { course } = this.props.patient;
      const { services } = course;
      const payment_option = course.payment_option;
      const { payments } = course;
      const { remain } = payments;
      const cbct = payments.cbct || false;
      let extra = null;
      if (cbct == true) {
        const cbct_service = services.filter(service => service.tag === 'CBCT_ANALYSIS' && service.paid == false);
        if (cbct_service && cbct_service.length > 0) {
          extra = cbct_service.pop();
        }
      }
      if (payment_option == "PO_ADVANCE" && remain > 0) {
        this.setState({ amount: { price: remain, extra: extra, course: true }, canSubmit: true });
      }
    }
  }*/

  setAmount(value) {
    this.setState({ amount: value });
    this.setState({ canSubmit: true });
  }

  paymentOptions() {
    const { patient: { course } } = this.props;
    const payment_option = course.payment_option;
    const { payments } = course;
    const { next, remain } = payments;
    const cbct = payments.cbct || false;
    const { services } = course;

    let extra = null;
    if (cbct == true) {
      const cbct_service = services.filter(service => service.tag === 'CBCT_ANALYSIS' && service.paid == false);
      if (cbct_service && cbct_service.length > 0) {
        extra = cbct_service.pop();
      }
    }

    if (remain > 0 || next > 0) {
      if (payment_option != "PO_ADVANCE") {
        return(
          <div className="form-group">
              <div className="radio-list">
              <label className="control-label">Доступные оплаты:</label>
              <label>
                <div className="radio">
                  <span>
                    <input type="radio" name="payment_option" value={1} onChange={() => this.setAmount({ price: next, course: true, extra: extra })}/>
                  </span>
                </div>
                {next} рублей
              </label>
              {next != remain
              ? (
                <label>
                  <div className="radio">
                    <span>
                      <input type="radio" name="payment_option" value={2} onChange={(e) => this.setAmount({ price: remain, course: true, extra: extra })}/>
                    </span>
                  </div>
                  Оплатить оставшуюся сумму целиком ({remain} рублей)
                </label>
              ) : null
              }
            </div>
          </div>
        )
      } else {
        return (
          <div className="form-group">
            <div>
              <div className="radio-list">
                <label>
                  <div className="radio">
                    <span>
                      <input type="radio" name="payment_option" value={1} onChange={() => this.setAmount({ price: next, course: true, extra: extra })}/>
                    </span>
                  </div>
                  Оплатить сумму {next} рублей
                </label>
                <br/>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  renderExtraPayments(course: TPatientData["course"]) {
    if (course.payments && course.payments.extra && Array.isArray(course.payments.extra) && course.payments.extra.length > 0) {
      const { extra } = course.payments;
      const extra_payments = [];
      extra.forEach((e, index) => {
        extra_payments.push(
          <label key={e.id}>
            <div className="radio">
              <span>
                <input
                  type="radio"
                  name="payment_option"
                  value={index}
                  onChange={() => this.setAmount({ price: e.price, course: false, extra: { ...e }})}
                />
              </span>
            </div>
            <FormattedMessage id={e.tag} /> {e.price} рублей
          </label>
        )
      })
      return (
        <div className="form-group">
          <div className="radio-list">
            <label className="control-label">Оплата дополнительных услуг:</label>
            {extra_payments}
          </div>
        </div>
      )
    }
  }

  requestToPay() {
    if (this.state.amount) {
      if (this.props.token && this.props.patient) {
        const token = this.props.token;
        const patient_id = this.props.patient.patient_id;
        this.setState({ showLoader: true });
        this.setState({ canSubmit: false });
        this.props.sendPatientPaymentRequest(patient_id, this.state.amount, token);
      }
    }
  }

  render() {
    const { patient } = this.props;
    if (patient && patient.course && Object.keys(patient.course).length) {
      const { course } = patient;
      const { course: { total_payments } } = patient;
      const paid_value = total_payments.paid;
      const paid_total = total_payments.total;
      const paid_remain = paid_total - paid_value;

      return (
        <div className="page-content-wrapper" id="offer-approve-section">
          <div className="page-content" style={{minHeight: 584}}>
            <div className="row">
              <div className="col-md-8">
                <Portlet as="main">
                  <PortletTitle iconClassName="icon-book-open">
                    Он-лайн оплата
                  </PortletTitle>
                  <div className="portlet-body">
                    <div>
                      <label>Оплачено {paid_value} из {paid_total} рублей</label>
                    </div>
                    {paid_remain > 0
                      ? (
                        <div>
                          {this.paymentOptions()}
                          {this.renderExtraPayments(course)}
                          <div>
                            {PAYMENT_SYSTEM_TYPE == PaymentSystem.SBERBANK ? (
                              <div className="form-group">
                                <textarea
                                  className="form-control"
                                  rows={10}
                                  readOnly
                                  defaultValue={SBERBANK_DISCLAIMER}
                                >
                                </textarea>
                              </div>
                            ) : null}
                            <Button
                              onClick={() => this.requestToPay()}
                              id="test-btn"
                              disabled={!this.state.canSubmit}
                            >
                              Оплатить
                            </Button>
                          </div>
                        </div>
                      ) : <div>Оплата внесена полностью</div>
                    }
                    {this.state.showLoader ? <Loader msg="Сейчас вы будете перенаправлены на форму оплаты" /> : null}
                  </div>
                </Portlet>
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return <Loader />
    }
  }
}

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PaymentInvoice);
