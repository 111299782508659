import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink } from "react-router-dom";

import { sendPassword } from "~/actions/restorePassword";
import remoteLog from "~/common/logging";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

import Logo from "./logo";
import Notification from "./notification";

const mapStateToProps = (state: RootState) => {
  return {
    errorMessage: state.errorMessage,
    lang: state.intl,
  };
};

const mapDispatchToProps = {
  sendMail: sendPassword,
};

function LoginErrorMessage({ errorMessage }: { errorMessage: string }) {
  if (!errorMessage) {
    return null;
  } else {
    return (
      <div className="alert alert-danger">
        <button type="button" className="close" data-close="alert" />
        <span id="login-error-msg">
          <FormattedMessage id={errorMessage} />
        </span>
      </div>
    );
  }
}

type ForgotPasswordProps = PropsFromRedux & { intl: IntlShape };

type ForgotPasswordState = {
  buttonDisabled: boolean;
  login: string | null;
};

class ForgotPassword extends Component<ForgotPasswordProps, ForgotPasswordState> {
  init: boolean;
  success: boolean;

  constructor(props: ForgotPasswordProps) {
    super(props);
    this.state = {
      buttonDisabled: true,
      login: null,
    };
    this.init = false;
    this.success = false;
    this.setValue = this.setValue.bind(this);
    this.remindPassword = this.remindPassword.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "forgot_password");
  }

  componentDidMount() {
    setDocumentTitle(this.props.intl.formatMessage({ id: "reminder.forgot" }));
  }

  remindPassword(login: string | null) {
    this.props.sendMail(login, this.props.lang.locale).then((response) => {
      if (response.status === true) {
        this.success = true;
        this.forceUpdate();
      }
    });
  }

  setValue(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value === null || value === undefined || value === "") {
      this.setState({ buttonDisabled: true, login: null });
    } else {
      this.setState({ buttonDisabled: false, login: value });
    }
  }

  render() {
    const { buttonDisabled, login } = this.state;

    return (
      <>
        <Notification />
        <Logo />

        <div className="content">
          {!this.success ? (
            <form
              className="forget-form"
              method="post"
              onSubmit={(e) => {
                e.preventDefault();
                this.init = true;
                this.remindPassword(login);
              }}
            >
              <h3>
                <FormattedMessage id="reminder.forgot" />
              </h3>
              {this.init ? <LoginErrorMessage errorMessage={this.props.errorMessage} /> : ""}
              <p>
                <FormattedMessage id="reminder.email" />
              </p>
              <div className="form-group">
                <div className="input-icon">
                  <i style={{ margin: "11px 2px 4px 10px" }} className="fa fa-user" />
                  <Input
                    type="text"
                    autoComplete="off"
                    placeholder={this.props.intl.formatMessage({ id: "reminder.placeholder.login" })}
                    name="login"
                    onChange={(event) => this.setValue(event)}
                    className="tw-pl-8"
                  />
                </div>
              </div>
              <div className="form-actions">
                <Button variant="outline" asChild >
                  <NavLink to="/">
                    <FormattedMessage id="reminder.back" />
                  </NavLink>
                </Button>

                <Button type="submit" className="pull-right" disabled={buttonDisabled}>
                  <FormattedMessage id="reminder.submit" />
                </Button>
              </div>
            </form>
          ) : (
            <div>
              <span>
                <FormattedMessage id="reminder.mail_send" />
              </span>
              <br />
              <NavLink to="/login">
                <span>
                  <FormattedMessage id="reminder.link" />
                </span>
              </NavLink>
            </div>
          )}
        </div>
      </>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(ForgotPassword));
