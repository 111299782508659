import type { bannersIsLoadingAction } from "../actions/banners";
import type { userIsLoadingAction, userLoggedInSuccess } from "../actions/login";
import type { getNotificationsAction, notificationWasShownAction } from "../actions/notifications";
import type { CommentType, RxType } from "../common/constants";

export function bannersIsLoading(
  state = false,
  action: ReturnType<typeof bannersIsLoadingAction>,
): boolean {
  switch (action.type) {
    case "BANNERS_IS_LOGGING_IN":
      return action.bannersIsLoading;

    default:
      return state;
  }
}

export function userIsLoading(
  state = true,
  action: ReturnType<typeof userIsLoadingAction>,
): boolean {
  switch (action.type) {
    case "USER_IS_LOGGING_IN":
      return action.userIsLoading;

    default:
      return state;
  }
}

export function notificationWasShown(
  state = true,
  action: ReturnType<typeof notificationWasShownAction>,
): boolean {
  switch (action.type) {
    case "NOTIFICATIONS_WAS_SHOWN":
      return action.notificationWasShown;

    default:
      return state;
  }
}

export type TUser = {
  elite_to_be_paid?: number;
  account_id: number;
  account_type_id: number;
  specialty_id: number;
  daily_token: string;
  email: string;
  email_notify: 0 | 1 | "None" | null;
  first_name: string;
  has_signed_offer: boolean;
  is_verified: boolean;
  last_name: string;
  login: string;
  middle_name: string;
  phone: string;
  telegram_id: string;
  telegram_notify: 0 | 1 | "None" | null;
  preferences: TPreferences;
  privileges: TAccountPrivileges;
  payment_available: boolean;
  payment_account?: boolean;
};

type TPreferences = {
  default_payment_method: null;
  dental_notation: 1 | 2 | 3;
  rx_type_id: RxType;
  perform_ipr: boolean | null;
  ipr_start_step: number | null;
  place_attachments_on_step: number | null;
  always_expand: boolean | null;
  incisor_relationship_overjet: number | null;
  incisor_relationship_overbite: number | null;
  upper_incisor_angulation: string | null;
  deep_bite_intrusion: boolean | null;
  incisors_extrusion: boolean | null;
  rotation_over_30dg: boolean | null;
  special_instructions: string | null;
};

type TAccountPrivileges = {
  easy_correction_form?: 1;
  show_v2?: 1;
  ups_deliveries?: 1;
  has_3shape_scanner?: 1;
  disable_bonus_system?: 1;
  accepted_yandex_metrika?: 1;
  test_plastic?: 1;
};

export function user(
  state: Partial<TUser> = {},
  action: ReturnType<typeof userLoggedInSuccess>,
): Partial<TUser> {
  switch (action.type) {
    case "USER_LOGGED_IN_SUCCESS":
      if (action.fields) {
        return action.fields;
      } else {
        return state;
      }

    default:
      return state;
  }
}

export type TComment = {
  account_id: TUser["account_id"];
  comment: string;
  commenter: {
    external: boolean;
    first_name: string;
    full_name: string;
    last_name: string;
    avatar: string | null;
  };
  timestamp: string;
  type: CommentType;
};

type CommentsState = TComment[] | Record<string, unknown>

type CommentsAction =
  | { type: "GET_PATIENT_COMMENTS", json: TComment[] }
  | { type: "POST_PATIENT_COMMENTS", json: TComment[] }
  | { type: "ERASE_COMMENTS" };

export function comments(state: CommentsState = {}, action: CommentsAction): CommentsState {
  switch (action.type) {
    case "GET_PATIENT_COMMENTS":
      return action.json;

    case "POST_PATIENT_COMMENTS":
      return action.json;

    case "ERASE_COMMENTS":
      return {};

    default:
      return state;
  }
}

type ErrorMessageAction =
  | { type: "USER_LOGGED_IN_ERROR", message: string }
  | { type: "USER_INIT_FAILURE", message: string };

export function errorMessage(state = "", action: ErrorMessageAction): string {
  switch (action.type) {
    case "USER_LOGGED_IN_ERROR":
      return action.message;

    case "USER_INIT_FAILURE":
      return action.message;

    default:
      return state;
  }
}

export type TNotification = TPaymentNotification;

type TPaymentNotification = {
  id: number;
  type: "waiting_for_payment";
  additional_data: {
    patient_id: number;
  };
};

export function notifications(
  state: TNotification[] = [],
  action: ReturnType<typeof getNotificationsAction>,
): TNotification[] {
  switch (action.type) {
    case "GET_NOTIFICATIONS":
      return action.notifications;

    default:
      return state;
  }
}
