import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { eraseStateProp } from "../../actions/dashboard";
import { getPatientId } from "../../actions/get_patient_id";
import { orderRetainers } from "../../actions/order_retainers";
import { updateMedia } from "../../actions/post_patient";
import { PaymentMethod,SOArch, SOMaterial } from "../../common/constants";
import remoteLog from "../../common/logging";
import { deployedRussia, deployedUSA } from "../../common/utils";
import { setDocumentTitle } from "../../hooks/use-document-title";
import type { RootState } from "../../store";
import Loader from "../common/loadingInProgress";
import { PatientNewInstructionsFiles } from "../patient/patient_new/patient_new_instructions_files";
import { Button } from "../ui/button";
import { Layout } from "../ui/layout";
import { Pending } from "../ui/pending";
import { Portlet, PortletTitle } from "../ui/portlet";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  patient: state.patient,
  media: state.media,
});

const mapDispatchToProps = {
  updateMedia,
  eraseStateProp,
  getPatientId,
  orderRetainers,
};

type PlanRetainersProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    patient_id: string;
  }>;

type PlanRetainersState = {
  isSubmitting: boolean;
  material: SOMaterial | null;
  existingScanUploaded: boolean;
  treatArchesOption: SOArch | null;
  quantity: number | null;
  comment: string | null;
};

class PlanRetainers extends Component<PlanRetainersProps, PlanRetainersState> {
  constructor(props: PlanRetainersProps) {
    super(props);
    this.state = {
      isSubmitting: false,
      material: null,
      existingScanUploaded: false,
      treatArchesOption: null,
      quantity: null,
      comment: "",
    };
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_retainers_page_body");
  }

  componentDidMount() {
    const { user } = this.props;

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }

    this.props.getPatientId(this.props.match.params.patient_id);
    this.props.eraseStateProp("media");

    setDocumentTitle(
      this.props.intl.formatMessage({
        id: deployedRussia() ? "pat.retainers.page.header" : "pat.retainers.page.header.usa",
      }),
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps: PlanRetainersProps) {
    if (
      nextProps.patient &&
      Object.keys(nextProps.patient).length > 0 &&
      nextProps.patient.course
    ) {
      const paymentMethod = nextProps.patient.course.payment_method;
      const can_order_retainers =
        nextProps.patient.course.correction.slice(-1)[0]?.order_options.can_order_retainers;
      if (can_order_retainers == false) {
        if (deployedUSA() && paymentMethod == PaymentMethod.CARD) {
          nextProps.history.push("/pages/payments");
        } else {
          nextProps.history.push("/pages/patients");
        }
      }
    }
  }

  async orderRetainersSubmit() {
    const { material, treatArchesOption, quantity, comment } = this.state;

    if ($("#submit-pacient-btn").attr("disabled")) {
      return;
    }

    if (!this.validateInstructions()) {
      $("#submit-pacient-btn").removeClass("-error");
      setTimeout(() => {
        $("#submit-pacient-btn").addClass("-error");
      }, 0);
      return;
    }

    this.setState({ isSubmitting: true });
    this.props.updateMedia(this.props.match.params.patient_id, { media: this.props.media });

    try {
      await this.props.orderRetainers(
        this.props.match.params.patient_id,
        material,
        treatArchesOption,
        quantity,
        comment,
      );
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  validateInstructions() {
    const { material, existingScanUploaded, treatArchesOption, quantity } = this.state;

    const useScanClicked = material == SOMaterial.USE_SCAN || existingScanUploaded ? true : false;
    const mediaKeys = Object.keys(this.props.media);
    const mediaAttachments = mediaKeys.map((elm) => this.props.media[elm].user_filename);
    const scanUploaded =
      existingScanUploaded ||
      !!mediaAttachments.filter((elmm) => {
        if (elmm) {
          const cond = elmm.includes("stl");
          return cond;
        }
      }).length;
    const quantityIsValid = deployedUSA() || (deployedRussia() && Boolean(quantity));

    $("#instruction-files").css({
      color: !useScanClicked ? "#34495e" : scanUploaded ? "#34495e" : "red",
    });

    $("#retainers-label").css({
      color: material != null ? "#34495e" : "red",
    });

    $("#treat-arches-id-label").css({
      color: treatArchesOption !== null ? "#34495e" : "red",
    });

    $("#quantity-label").css({
      color: quantityIsValid ? "#34495e" : "red",
    });

    return (
      Boolean(material != null ? (useScanClicked ? scanUploaded : true) : false) &&
      treatArchesOption !== null &&
      quantityIsValid
    );
  }

  render() {
    if (!this.props.patient.patient_id) {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        </Layout>
      );
    }

    const { material, existingScanUploaded, isSubmitting } = this.state;

    return (
      <Layout>
        <div className="row">
          <div className="col-md-12">
            <Portlet as="main" id="retainers-section-head">
              <PortletTitle iconClassName="icon-book-open">
                <FormattedMessage
                  id={deployedRussia() ? "pat.retainers.page.header" : "pat.retainers.page.header.usa"}
                />
              </PortletTitle>

              <div className="portlet-body">
                <form
                  onSubmit={(event) => {
                    event.preventDefault();
                    this.orderRetainersSubmit();
                  }}
                >
                  <MaterialRadioGroup
                    material={this.state.material}
                    onMaterialChange={(newMaterial) => this.setState({ material: newMaterial })}
                  />

                  <AnimatePresence>
                    {material == SOMaterial.USE_SCAN ? (
                      <ScanUploadRoot>
                        <PatientNewInstructionsFiles style={{ paddingBottom: 20 }} />

                        {deployedUSA() ? (
                          <div style={{ marginLeft: 25 }} className="checkbox">
                            <label id="retainers-use-itero-scan-label">
                              <input
                                type="checkbox"
                                id="retainers-use-itero-scan-value"
                                name="existingScanUploaded"
                                checked={existingScanUploaded}
                                onChange={() =>
                                  this.setState({
                                    existingScanUploaded: !this.state.existingScanUploaded,
                                  })
                                }
                              />
                              <FormattedMessage id="UPLOASES_FILES_EXIST" />
                            </label>
                          </div>
                        ) : null}

                        <div id="form_retainers_error"> </div>
                      </ScanUploadRoot>
                    ) : null}
                  </AnimatePresence>

                  <ArchRadioGroup
                    arch={this.state.treatArchesOption}
                    onArchChange={(newArch) => this.setState({ treatArchesOption: newArch })}
                  />

                  {deployedRussia() ? (
                    <QuantityRadioGroup
                      quantity={this.state.quantity}
                      onQuantityChange={(newQuantity) => this.setState({ quantity: newQuantity })}
                    />
                  ) : null}

                  {deployedUSA() ? (
                    <div className="form-group" id="retainers-section">
                      <label id="retainers-label" className="control-label" style={{ fontWeight: "900" }}>
                        <FormattedMessage id="RETAINERS_COMMENT" />
                      </label>
                      <textarea
                        className="form-control"
                        rows={5}
                        id="retainers-comment-value"
                        name="comment"
                        onChange={(e) => this.setState({ comment: e.target.value })}
                      />
                    </div>
                  ) : null}

                  <Button
                    id="submit-pacient-btn"
                    variant="action"
                    disabled={isSubmitting}
                  >
                    <Pending isPending={isSubmitting}>
                      <FormattedMessage id="pat.payments.buttons.submit" />
                    </Pending>
                  </Button>
                </form>
              </div>
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanRetainers));

function MaterialRadioGroup({
  material,
  onMaterialChange,
}: {
  material: SOMaterial | null;
  onMaterialChange(newMaterial: SOMaterial): void;
}) {
  type TOption = { value: SOMaterial; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SOMaterial.LAST_STAGE, intlId: "EXTRA_SERVICE_OPTION_LAST_STAGE" },
    { value: SOMaterial.USE_SCAN, intlId: "EXTRA_SERVICE_OPTION_USE_SCAN" },
    { value: SOMaterial.USE_IMPRESSIONS, intlId: "EXTRA_SERVICE_OPTION_USE_IMPRESSIONS" },
  ];

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="retainers-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`retainers-material-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`retainers-material-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="material"
              value={option.value}
              checked={option.value == material}
              onChange={() => onMaterialChange(option.value)}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>
    </fieldset>
  );
}

function ArchRadioGroup({
  arch,
  onArchChange,
}: {
  arch: SOArch | null;
  onArchChange(newArch: SOArch): void;
}) {
  type TOption = { value: SOArch; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SOArch.BOTH, intlId: deployedRussia() ? "TA_BOTH" : "TA_BOTH_RETAINERS_USA" },
    { value: SOArch.UPPER, intlId: deployedRussia() ? "TA_UPPER" : "TA_UPPER_RETAINERS_USA" },
    { value: SOArch.LOWER, intlId: deployedRussia() ? "TA_LOWER" : "TA_LOWER_RETAINERS_USA" },
  ];

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="treat-arches-id-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ARCHES_SELECT" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`treat-arches-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`treat-arches-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="treat_arches_id"
              value={option.value}
              checked={option.value == arch}
              onChange={() => onArchChange(option.value)}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>

      <div id="form_tooth_arch_error"> </div>
    </fieldset>
  );
}

function QuantityRadioGroup({
  quantity,
  onQuantityChange,
}: {
  quantity: number | null;
  onQuantityChange(newQuantity: number): void;
}) {
  const options = [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
  ] as const;

  return (
    <fieldset className="tw-mb-4 tw-space-y-1" aria-required="true">
      <legend id="quantity-label" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="RETAINERS_QUANTITY" />
        <RequiredIndicator />
      </legend>

      <div className="tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`retainers-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`retainers-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="retainers"
              value={option.value}
              checked={option.value == quantity}
              onChange={() => onQuantityChange(option.value)}
            />
            {option.text}
          </label>
        ))}
      </div>
    </fieldset>
  );
}

function RequiredIndicator() {
  return (
    <span aria-hidden="true" className="required tw-relative tw-bottom-1 tw-left-1 tw-text-[17px]">
      *
    </span>
  );
}

function ScanUploadRoot({ children }: { children: React.ReactNode }) {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <>
      <motion.div
        // PatientNewInstruionsFiles h4 adds a margin-top and padding-top that causes
        // the extra vertical space. Had to remove it from here.
        className="tw-overflow-hidden [&_h4]:tw-mt-0 [&_h4]:tw-pt-0"
        {...(shouldReduceMotion ? {} : animationProps)}
      >
        {children}
      </motion.div>
    </>
  );
}
