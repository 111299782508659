import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";

import { changePassword } from "~/actions/edit_doctor";
import { addUserActionNotification } from "~/actions/user_notification";
import remoteLog from "~/common/logging";
import { Button } from "~/components/ui/button";
import { Layout } from "~/components/ui/layout";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import {
  levenshteinRatio,
  MIN_PASSWORD_LENGTH,
  PASSWORD_LOGIN_MAX_RATIO,
  REG_DIGITS,
  REG_LOWER_CHARS,
  REG_SPEC_CHARS,
  REG_UPPER_CHARS,
  SPEC_CHAR_TEXT,
} from "~/components/validation/password_validation_constants";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  doctor: state.doctor,
});

const mapDispatchToProps = {
  changePassword,
  addUserActionNotification,
};

type DoctorChangePasswordPageProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

type DoctorChangePasswordPageState = {
  new_password: string;
  new_password_repeat: string;
  disabled: boolean;
  inputHidden: boolean;
  password_length: boolean;
  password_digits: boolean;
  password_spec_chars: boolean;
  password_upper_chars: boolean;
  password_lower_chars: boolean;
  password_match: boolean;
  password_similar: boolean;
};

class DoctorChangePasswordPage extends Component<
  DoctorChangePasswordPageProps,
  DoctorChangePasswordPageState
> {
  constructor(props: DoctorChangePasswordPageProps) {
    super(props);
    this.state = {
      new_password: "",
      new_password_repeat: "",
      disabled: true,
      inputHidden: true,
      password_length: false,
      password_digits: false,
      password_spec_chars: false,
      password_upper_chars: false,
      password_lower_chars: false,
      password_match: false,
      password_similar: false,
    };
    this.submitNewPassword = this.submitNewPassword.bind(this);
    this.showHidePassword = this.showHidePassword.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "doctor_change_password_page");
  }

  componentDidMount() {
    setDocumentTitle(this.props.intl.formatMessage({ id: "change.password" }));
  }

  componentDidUpdate() {
    if (this.props.doctor) {
      if (this.props.doctor.account_id) {
        this.props.history.push("/pages/my_account");
      }
    }
  }

  validateFieldsSubmit() {
    const { new_password, new_password_repeat } = this.state;

    let password_digits = false;
    let password_length = false;
    let password_spec_chars = false;
    let password_match = false;
    let password_similar = false;
    let password_upper_chars = false;
    let password_lower_chars = false;

    if (new_password != "") {
      password_digits = REG_DIGITS.test(new_password);
      password_upper_chars = REG_UPPER_CHARS.test(new_password);
      password_lower_chars = REG_LOWER_CHARS.test(new_password);
      password_spec_chars = REG_SPEC_CHARS.test(new_password);
      password_length = new_password.length >= MIN_PASSWORD_LENGTH;
      password_match = new_password == new_password_repeat;
      password_similar =
        levenshteinRatio(this.props.user.login.toLowerCase(), new_password.toLowerCase()) <=
        PASSWORD_LOGIN_MAX_RATIO;
    }

    const isValid =
      password_digits &&
      password_upper_chars &&
      password_lower_chars &&
      password_length &&
      password_similar &&
      password_match &&
      password_spec_chars;

    this.setState({
      password_digits,
      password_length,
      password_spec_chars,
      password_similar,
      password_match,
      password_upper_chars,
      password_lower_chars,
      disabled: !isValid,
    });

    return isValid;
  }

  renderCheckList() {
    const doneStyles = { color: "green", fontWeight: 700 };
    const unDoneStyles = { color: "red", fontWeight: 500 };

    const {
      password_digits,
      password_length,
      password_lower_chars,
      password_upper_chars,
      password_spec_chars,
      password_similar,
      password_match,
    } = this.state;

    return (
      <div style={{ marginBottom: "20px" }}>
        <div style={{ marginBottom: "20px", fontWeight: 600 }}>
          <h4>
            <FormattedMessage id="password.requirements.header" />
          </h4>
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_digits == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.digits" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i
            className="icon-check"
            style={password_upper_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.upper.chars" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i
            className="icon-check"
            style={password_lower_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.lower.chars" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_length == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.length" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }} title={SPEC_CHAR_TEXT}>
          <i
            className="icon-check"
            style={password_spec_chars == true ? doneStyles : unDoneStyles}
          />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.special" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_match == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.match" />
        </div>
        <div style={{ margin: "10px", fontWeight: 600 }}>
          <i className="icon-check" style={password_similar == true ? doneStyles : unDoneStyles} />
          &nbsp;&nbsp;
          <FormattedMessage id="password.requirements.similar" />
        </div>
      </div>
    );
  }

  submitNewPassword() {
    if (this.validateFieldsSubmit()) {
      const { new_password } = this.state;
      void this.props.changePassword(new_password);
    }
  }

  showHidePassword() {
    const { inputHidden } = this.state;
    this.setState({ inputHidden: !inputHidden });
  }

  render() {
    const { disabled, inputHidden } = this.state;
    const { intl } = this.props;

    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-user">
            <FormattedMessage id="change.password" />
          </PortletTitle>
          <form
            style={{ maxWidth: "400px" }}
            className="login-form"
            method="post"
            onSubmit={(e) => {
              e.preventDefault();
              this.submitNewPassword();
            }}
          >
            {this.renderCheckList()}
            <div className="form-group">
              <div className="input-icon">
                <i className="fa fa-lock" />
                <label htmlFor="new-password-input" className="control-label visible-ie8 visible-ie9">
                  <FormattedMessage id="new_password.new_password" />
                </label>
                <input
                  id="new-password-input"
                  className="form-control placeholder-no-fix"
                  type={inputHidden ? "password" : "text"}
                  autoComplete="off"
                  placeholder={intl.formatMessage({ id: "new_password.new_password" })}
                  name="new_password"
                  onChange={(e) =>
                    this.setState({ new_password: e.target.value }, () => this.validateFieldsSubmit())
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor="new-password-repeat-input"
                className="control-label visible-ie8 visible-ie9"
              >
                <FormattedMessage id="login.password" />
              </label>
              <div className="input-icon">
                <i className="fa fa-lock" />
                <input
                  id="new-password-repeat-input"
                  className="form-control placeholder-no-fix"
                  type={inputHidden ? "password" : "text"}
                  autoComplete="off"
                  placeholder={intl.formatMessage({ id: "new_password.confirm" })}
                  name="new_password_repeat"
                  onChange={(e) =>
                    this.setState({ new_password_repeat: e.target.value }, () =>
                      this.validateFieldsSubmit(),
                    )
                  }
                />
              </div>
            </div>
            <div className="form-actions">
              <Button type="submit" className="pull-right" disabled={disabled}>
                <FormattedMessage id="BTN_SUBMIT" />
              </Button>
              <i
                tabIndex={0}
                role="checkbox"
                aria-checked={!inputHidden}
                aria-label={intl.formatMessage({ id: "login.show_password" })}
                className={inputHidden ? "icon-lock" : "icon-lock-open"}
                style={{ fontSize: "18px" }}
                onClick={() => this.showHidePassword()}
                onKeyDown={(event) => {
                  if (event.key == " ") {
                    this.showHidePassword();
                  }
                }}
              />
            </div>
          </form>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(DoctorChangePasswordPage));
