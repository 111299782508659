import type { TInstructions } from "~/reducers/dashboard";

export type PatientNewFormValues = {
  arch: "1" | "2" | "3" | null;
  material: null;
  vertical_overlap: null;
  vertical_overlap_comment: string;
};

/**
 * When you update a patient, depending on the status, form values could be `undefined`.
 */
export type PatientUpdateFormValues = PatientNewFormValues | undefined;

export function validatePatientName(
  instructions: Partial<TInstructions>,
  emptyFields: string[],
  { ignoreDoctorId = false }: { ignoreDoctorId?: boolean } = {},
): boolean {
  return (
    (["doctor_id", "first_name", "last_name"] as const).filter((field) => {
      if (ignoreDoctorId && field == "doctor_id") {
        return false;
      }

      let value = instructions[field];
      if (typeof value == "string") {
        value = value.trim();
      }
      const isValid = Boolean(value);

      $(`[name="${field}"]`)
        .parent()
        .find("label")
        .css({ color: field !== "doctor_id" && !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push("patient-block-body");
      }

      return !isValid;
    }).length == 0
  );
}

export function validateMaterial(
  formValues: PatientUpdateFormValues,
  emptyFields: string[],
): boolean {
  return (
    (["material"] as const).filter((elm) => {
      const isValid =
        formValues != null &&
        Object.prototype.hasOwnProperty.call(formValues, elm) &&
        formValues[elm] !== null;

      $(`#validation-${elm}`).css({ color: !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push(`validation-${elm}`);
      }

      return !isValid;
    }).length == 0
  );
}

export function validateVerticalOverlapComment(
  formValues: PatientUpdateFormValues,
  emptyFields: string[],
): boolean {
  const vertical_overlap_comment =
    formValues &&
    Object.prototype.hasOwnProperty.call(formValues, "vertical_overlap") &&
    (formValues.vertical_overlap === 1 || formValues.vertical_overlap === 2);

  return (
    (["vertical_overlap_comment"] as const).filter((elm) => {
      if (!vertical_overlap_comment) {
        return false;
      }

      const isValid =
        Object.prototype.hasOwnProperty.call(formValues, elm) && formValues[elm] !== "";

      $(`#validation-${elm}`).css({ color: !isValid ? "red" : "#34495e" });

      if (!isValid) {
        emptyFields.push(`validation-${elm}`);
      }

      return !isValid;
    }).length == 0
  );
}

export function validateTestPlastic(
  { test_plastic }: Partial<TInstructions>,
  emptyFields: string[],
): boolean {
  const isValid = typeof test_plastic == "boolean";

  $(`#validation-test_plastic`).css({ color: !isValid ? "red" : "#34495e" });

  if (!isValid) {
    emptyFields.push("validation-test_plastic");
  }

  return isValid;
}

export function validateArch(formValues: PatientUpdateFormValues, emptyFields: string[]): boolean {
  const isValid =
    formValues != null &&
    Object.prototype.hasOwnProperty.call(formValues, "arch") &&
    !Number.isNaN(formValues["arch"]) &&
    formValues["arch"] !== null;

  if (!isValid) {
    emptyFields.push("validation-arch");
  }

  $("#validation-arch").css({ color: !isValid ? "red" : "#34495e" });

  return isValid;
}
