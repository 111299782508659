import { formSubmitNotification } from "~/components/notifications/form_submit_pop";
import { API_POST_NEW_MIDTERM_CORRECTION, API_PUT_PATIENT } from "~/config";
import type { TPatient, TPrescription, TUserActionNotification } from "~/reducers/dashboard";
import type { AppDispatch } from "~/store";

import { patientIsSavingSuccess } from "./post_patient";
import { addUserActionNotification } from "./user_notification";

export function sendPatientCorrection(
  patient_id: TPatient["patient_id"],
  media_info: Record<string, unknown>,
  s3_media: Record<string, unknown>,
  links: string[] | null = null,
  stage: number,
  prescription: TPrescription,
  with_ct: boolean | null = null, // must be null if absent, backend validation expects it
  test_plastic: boolean | null = null,
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.form.submitted.ok",
    level: "success",
    position: "tc",
    autoDismiss: 10,
    children: formSubmitNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.correction.create.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  const data = { stage, prescription, with_ct, test_plastic };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await fetch(API_POST_NEW_MIDTERM_CORRECTION(patient_id), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Error occurred trying to order correction: API_POST_NEW_MIDTERM_CORRECTION");
      }
      await response.json();

      const response2 = await fetch(API_PUT_PATIENT(patient_id), {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ media_info, s3_media, links }),
      });
      if (!response2.ok) {
        throw new Error("Error occurred trying to order correction: API_PUT_PATIENT");
      }
      const json = await response2.json();

      dispatch(patientIsSavingSuccess(true));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
      dispatch(patientIsSavingSuccess(false));
    } catch (err) {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      throw err;
    }
  };
}
