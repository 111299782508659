import React from "react";
import { FormattedMessage, type MessageDescriptor } from "react-intl";

import type { TCorrection } from "~/reducers/dashboard";

type TOption = { value: number; intlId: MessageDescriptor["id"] };

const options: TOption[] = [
  { value: 1, intlId: "C_TEST_PLASTIC_YES" },
  { value: 0, intlId: "C_TEST_PLASTIC_NO" },
];

export function TestPlaticOptions({
  value,
  onValueChange,
}: {
  value?: TCorrection["test_plastic"];
  onValueChange: (newTestPlastic: boolean) => void;
}) {
  return (
    <fieldset aria-required="true" className="tw-mb-[15px] tw-w-full">
      <legend id="validation-test_plastic" className="tw-mb-1 tw-border-0 tw-text-sm tw-font-bold">
        <FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />
        <span
          aria-hidden="true"
          className="required tw-relative tw-bottom-1 tw-left-1 tw-text-[17px]"
        >
          *
        </span>
      </legend>

      <div className="tw-mb-0 tw-mt-1 tw-flex tw-flex-col tw-gap-1.5">
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`test-plastic-value-${option.value}`}
            className="tw-flex tw-items-center tw-gap-2"
          >
            <input
              id={`test-plastic-value-${option.value}`}
              className="tw-m-0 focus-visible:tw-outline-offset-[3px]"
              type="radio"
              name="test_plastic"
              value={option.value}
              checked={value == Boolean(option.value)}
              onChange={(e) => onValueChange(Boolean(parseInt(e.target.value, 10)))}
            />
            <FormattedMessage id={option.intlId} />
          </label>
        ))}
      </div>
    </fieldset>
  );
}
