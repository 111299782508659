import * as Dialog from "@radix-ui/react-dialog";
import { StarFilledIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { AnimatePresence, type AnimationProps, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useSpinDelay } from "spin-delay";

import { addUserActionNotification } from "~/actions/user_notification";
import remoteLog from "~/common/logging";
import { FormatDate } from "~/components/common/FormatDate";
import { PersonName } from "~/components/common/PersonName";
import Notification from "~/components/notification";
import { Button } from "~/components/ui/button";
import { DialogClose } from "~/components/ui/dialog";
import { Pending } from "~/components/ui/pending";
import { useAppDispatch } from "~/store";

import {
  type TFeedbackMeeting,
  type TMeeting,
  useDelayMeetingFeedbackMutation,
  useGetMeetingFeedbackQuery,
  usePostMeetingFeedbackMutation,
} from "./meetings_api";
import { Expert } from "./meetings_page";

const animationProps: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { duration: 0.4 } },
  exit: { opacity: 0, transition: { duration: 0.15 } },
};

export function MeetingFeedbackPopUp() {
  const [isOpen, setIsOpen] = useState(false);

  const feedbackQuery = useGetMeetingFeedbackQuery(undefined);
  const recentMeeting = feedbackQuery.data;

  const dispatch = useAppDispatch();
  const [postFeedback, postFeedbackResult] = usePostMeetingFeedbackMutation();
  const [delayFeedback, delayFeedbackResult] = useDelayMeetingFeedbackMutation();

  const isPostingFeedback = useSpinDelay(postFeedbackResult.isLoading, {
    delay: 100,
    minDuration: 300,
  });

  const isDelayingFeedback = useSpinDelay(delayFeedbackResult.isLoading, {
    delay: 100,
    minDuration: 300,
  });

  useEffect(() => {
    if (!recentMeeting) {
      return;
    }
    const timeout = window.setTimeout(() => setIsOpen(true), 1000);
    return () => window.clearTimeout(timeout);
  }, [recentMeeting]);

  if (!recentMeeting) {
    return null;
  }

  const handlePostFeedback = async (data: Omit<TFeedbackMeeting, "meeting_id">) => {
    if (isPostingFeedback) {
      return;
    }
    try {
      await postFeedback({ ...data, meeting_id: recentMeeting.id }).unwrap();
      dispatch(
        addUserActionNotification({
          message: "meetings.feedback.ok",
          level: "success",
          position: "tl",
          autoDismiss: 4,
        }),
      );
    } catch (err) {
      remoteLog(err, "meeting_feedback_form.handle_post_feedback");
      setIsOpen(false);
    }
  };

  const handleRejectFeedback = async () => {
    if (isPostingFeedback) {
      return;
    }
    try {
      await postFeedback({ comment: "", rating: 0, meeting_id: recentMeeting.id }).unwrap();
    } catch (err) {
      remoteLog(err, "meeting_feedback_form.handle_reject_feedback");
      setIsOpen(false);
    }
  };

  const handleDelayFeedback = async () => {
    if (isDelayingFeedback) {
      return;
    }
    try {
      await delayFeedback({ meeting_id: recentMeeting.id }).unwrap();
    } catch (err) {
      remoteLog(err, "meeting_feedback_form.handle_delay_feedback");
      setIsOpen(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen ? (
        <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
          <Dialog.Portal>
            <motion.div {...animationProps} className="tw-fixed tw-inset-0 tw-z-[100]">
              <Dialog.Overlay className="tw-fixed tw-inset-0 tw-z-[100] tw-bg-black/20" />

              <Dialog.Content
                className={clsx(
                  "tw-fixed tw-left-1/2 tw-top-1/2 tw-z-[100] -tw-translate-x-1/2 -tw-translate-y-1/2",
                  "tw-w-full tw-border tw-bg-white tw-p-6 tw-shadow-lg",
                  "sm:tw-max-w-xl sm:tw-rounded-lg",
                )}
              >
                <Dialog.Title className="tw-m-0 tw-text-xl tw-font-semibold">
                  <FormattedMessage id="meetings.feedback.title" />
                </Dialog.Title>

                <FeedbackDescription meeting={recentMeeting} />
                <FeedbackForm
                  meeting={recentMeeting}
                  onPostFeedback={handlePostFeedback}
                  onDelayFeedback={handleDelayFeedback}
                  isPostingFeedback={isPostingFeedback}
                  isDelayingFeedback={isDelayingFeedback}
                />

                <DialogClose onClick={handleRejectFeedback} />
              </Dialog.Content>
            </motion.div>
          </Dialog.Portal>
        </Dialog.Root>
      ) : null}
      {/*
        * NOTE: without this <Notification />, toast message will NOT appear on the `pages/patients` page (the most popular page).
        */}
      <Notification />
    </AnimatePresence>
  );
}

function FeedbackDescription({ meeting }: { meeting: TMeeting }) {
  function renderPatient(patient: TMeeting["patients"][0]) {
    return (
      <>
        <PersonName as="strong" person={patient} /> (id: {patient.patient_id})
      </>
    );
  }

  return (
    <div className="tw-mt-5 tw-space-y-3.5 tw-leading-relaxed">
      <Expert expert={meeting.expert} />

      <div>
        <FormattedMessage id="meetings.feedback.description.1" />,{" "}
        <FormatDate value={meeting.start_time} />{" "}
        <FormattedMessage id="meetings.feedback.description.2" />
        {meeting.patients.length == 1 ? (
          <FormattedMessage id="meetings.feedback.description.p1" />
        ) : (
          <FormattedMessage id="meetings.feedback.description.p2" />
        )}
        {meeting.patients.length == 1 ? (
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          <>{renderPatient(meeting.patients[0]!)}.</>
        ) : (
          <ul className="tw-mt-2">
            {meeting.patients.map((patient) => (
              <li key={patient.patient_id}>{renderPatient(patient)}</li>
            ))}
          </ul>
        )}
      </div>

      <FormattedMessage tagName="p" id="meetings.feedback.description.3" />
    </div>
  );
}

function FeedbackForm({
  onPostFeedback,
  onDelayFeedback,
  isPostingFeedback,
  isDelayingFeedback,
}: {
  meeting: TMeeting;
  onPostFeedback(data: Omit<TFeedbackMeeting, "meeting_id">): void;
  onDelayFeedback(): void;
  isPostingFeedback: boolean;
  isDelayingFeedback: boolean;
}) {
  const [comment, setComment] = useState("");
  const [rating, setRating] = useState(0);

  const isCommentEmpty = comment.trim().length == 0;
  const isRatingEmpty = rating == 0;

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    onPostFeedback({ comment: comment || null, rating: rating || null });
  }

  return (
    <form className="tw-mt-3 tw-space-y-3" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="comment" className="tw-font-semibold">
          <FormattedMessage id="meetings.feedback.comment" />:
        </label>
        <Textarea
          id="comment"
          rows={5}
          maxLength={2048}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </div>

      <StarRating rating={rating} onRatingChange={setRating} />

      <div className="tw-flex tw-gap-2">
        <Button
          type="submit"
          rounded
          disabled={(isCommentEmpty && isRatingEmpty) || isPostingFeedback}
        >
          <Pending isPending={isPostingFeedback}>
            <FormattedMessage id="meetings.feedback.submit" />
          </Pending>
        </Button>

        <Dialog.Trigger asChild>
          <Button
            type="button"
            rounded
            variant="secondary"
            disabled={isDelayingFeedback}
            onClick={onDelayFeedback}
          >
            <Pending isPending={isDelayingFeedback}>
              <FormattedMessage id="meetings.feedback.later" />
            </Pending>
          </Button>
        </Dialog.Trigger>
      </div>
    </form>
  );
}

function Textarea({ className, ...props }: React.ComponentProps<"textarea">) {
  return (
    <textarea
      {...props}
      className={clsx(
        "tw-flex tw-min-h-[80px] tw-w-full tw-rounded-md tw-border tw-border-[#e4e4e7]",
        "tw-px-3 tw-py-2 tw-text-base",
        "focus-visible:tw-outline-none",
        "disabled:tw-cursor-not-allowed disabled:tw-opacity-50 md:tw-text-sm",
        className,
      )}
    />
  );
}

function StarRating({
  rating,
  onRatingChange,
}: {
  rating: number;
  onRatingChange(newRating: number): void;
}) {
  const [hoveredRating, setHoveredRating] = useState(0);

  return (
    <fieldset>
      <legend className="tw-mb-[5px] tw-border-0 tw-text-sm tw-font-semibold">
        <FormattedMessage id="meetings.feedback.rating" />:
      </legend>

      <div className="tw-flex tw-gap-1">
        {[1, 2, 3, 4, 5].map((star) => {
          const fillCondition = hoveredRating == 0 ? star <= rating : star <= hoveredRating;

          return (
            <div key={star}>
              <label htmlFor={`rating-${star}`} className="tw-sr-only">
                {star} star
              </label>
              <input
                id={`rating-${star}`}
                type="radio"
                name="rating"
                value={star}
                checked={star == rating}
                onChange={() => {
                  onRatingChange(star);
                  setHoveredRating(star);
                }}
                onBlur={() => setHoveredRating(0)}
                className="tw-sr-only [&:focus-visible+svg]:tw-ring-2 [&:focus-visible+svg]:tw-ring-blue-500"
              />
              <StarFilledIcon
                className={clsx(
                  "tw-h-8 tw-w-8 tw-cursor-pointer tw-rounded-md tw-transition-all tw-duration-300",
                  fillCondition ? "tw-text-yellow-400" : "tw-text-gray-300",
                  { "tw-scale-125": star == hoveredRating },
                )}
                onClick={() => {
                  onRatingChange(star);
                  setHoveredRating(0);
                }}
                onMouseOver={() => setHoveredRating(star)}
                onMouseLeave={() => setHoveredRating(0)}
              />
            </div>
          );
        })}
      </div>
    </fieldset>
  );
}
