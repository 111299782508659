import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import clsx from "clsx";
import React, { forwardRef } from "react";

const buttonVariants = cva(
  clsx(
    "tw-inline-flex tw-items-center tw-justify-center tw-no-underline tw-transition-colors",
    "tw-whitespace-nowrap tw-border-2 tw-border-solid",
    "focus-visible:tw-outline-none focus-visible:tw-ring-2",
    "focus-visible:tw-ring-blue-600 focus-visible:tw-ring-offset-1",
    "disabled:tw-cursor-not-allowed disabled:tw-opacity-[.65]",
  ),
  {
    variants: {
      variant: {
        default: clsx(
          "tw-border-primary tw-bg-primary tw-text-white",
          "hover:tw-border-[#26a1ab] hover:tw-bg-[#26a1ab]",
          "active:tw-border-[#26a1ab] active:tw-bg-[#26a1ab]",
          "disabled:tw-border-[#32c5d2] disabled:tw-bg-[#32c5d2]",
        ),
        outline: clsx(
          "tw-border-[#acb5c3] tw-bg-white tw-text-[#acb5c3]",
          "hover:tw-bg-[#acb5c3] hover:tw-text-[#fafcfb]",
          "active:tw-bg-[#acb5c3] active:tw-text-[#fafcfb]",
        ),
        secondary: clsx(
          "tw-border-[#efefef] tw-bg-[#efefef] tw-text-[#34495e]",
          "hover:tw-border-[#e0e0e0] hover:tw-bg-[#e0e0e0]",
          "active:tw-border-[#e0e0e0] active:tw-bg-[#e0e0e0]",
        ),
        action: clsx(
          "tw-border-[#26a1ab] tw-bg-[#26a1ab] tw-text-white",
          "hover:tw-opacity-75 active:tw-opacity-75",
        ),
        blue: clsx(
          "tw-border-[#3598dc] tw-bg-[#3598dc] tw-text-white",
          "hover:tw-border-[#217ebd] hover:tw-bg-[#217ebd]",
          "active:tw-border-[#217ebd] active:tw-bg-[#217ebd]",
          "disabled:tw-border-[#3598dc] disabled:tw-bg-[#3598dc]",
        ),
        grey: clsx(
          "tw-border-[#e1e5ec] tw-bg-[#e1e5ec] tw-text-[#666]",
          "hover:tw-border-[#c2cad8] hover:tw-bg-[#c2cad8]",
          "active:tw-border-[#c2cad8] active:tw-bg-[#c2cad8]",
        ),
        danger: clsx(
          "tw-border-[#e7505a] tw-bg-[#e7505a] tw-text-white",
          "hover:tw-border-[#e12330] hover:tw-bg-[#e12330]",
          "active:tw-border-[#e12330] active:tw-bg-[#e12330]",
        ),
      },
      size: {
        default: "tw-px-3.5 tw-py-1.5",
      },
      rounded: {
        true: "tw-rounded-md",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & { asChild?: boolean };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { className, variant, size, rounded, asChild = false, ...props },
  forwardedRef,
) {
  const Comp = asChild ? Slot : "button";

  return (
    <Comp
      ref={forwardedRef}
      className={buttonVariants({ variant, size, rounded, className })}
      {...props}
    />
  );
});
