import React, { Component } from 'react';
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { Redirect, type RouteComponentProps } from "react-router-dom";
import { Field,reduxForm } from "redux-form";

import { addClinic } from '~/actions/clinics';
import remoteLog from '~/common/logging';
import { deployedRussia } from "~/common/utils";
import {
  isLogisticPhoneNumber,
  isPhoneNumber,
  required,
  validateZIPCode,
  validEmail,
} from '~/common/validations/clinic';
import { Button } from '~/components/ui/button';
import { Layout } from '~/components/ui/layout';
import { Portlet, PortletTitle } from '~/components/ui/portlet';
import ClinicInput from "~/components/validation/ValidateNewClinic";
import { setDocumentTitle } from '~/hooks/use-document-title';
import type { RootState } from '~/store';

import Schedule, { DEFAULT_SCHEDULE } from "./schedule";

const mapStateToProps = (state: RootState) => ({
    user: state.user,
    clinic: state.clinic,
});

const mapDispatchToProps = {
    addData: addClinic,
};

type ClinicNewProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps

type ClinicNewState = {
  region: unknown[];
  cities: unknown[];
  city_id: null;
  workingDays: typeof DEFAULT_SCHEDULE;
};

class ClinicNew extends Component<ClinicNewProps, ClinicNewState> {
    constructor(props: ClinicNewProps){
        super(props)
        this.state = {
            region: [],
            cities: [],
            city_id: null,
            workingDays: { ...DEFAULT_SCHEDULE }
        }
        this.submit = this.submit.bind(this);
        this.onWorkingDayStatusChanged = this.onWorkingDayStatusChanged.bind(this);
        this.onStartTimeChanged = this.onStartTimeChanged.bind(this);
        this.onEndTimeChanged = this.onEndTimeChanged.bind(this);
    }

    componentDidCatch(e: Error) {
        remoteLog(e, 'clinic_new_page');
    }

    componentDidMount() {
        const { user } = this.props;

        _paq.push(['HeatmapSessionRecording::enable']);
        if (user && user.account_id) {
            _paq.push(['setUserId', user.account_id.toString()]);
        }
        setDocumentTitle(this.props.intl.formatMessage({ id: "clin_view.add.header" }));
    }

    componentDidUpdate(prevProps: ClinicNewProps) {
        const {clinic} = this.props;
        if(clinic.clinic_id && clinic.clinic_id !== prevProps.clinic.clinic_id){
            const id = this.props.clinic.clinic_id;
            this.props.history.push(`/pages/doctor/clinics/${id}`);
        }
    }

    onWorkingDayStatusChanged(event: React.ChangeEvent<HTMLInputElement>) {
        const { workingDays } = this.state;
        const weekday = event.currentTarget.name;
        workingDays[weekday] = {
            ...workingDays[weekday],
            checked: !workingDays[weekday].checked
        };
        this.setState(workingDays);
    }

    onStartTimeChanged(weekday: string, time: { startTime: string }) {
        const { workingDays } = this.state;
        workingDays[weekday] = {
            ...workingDays[weekday],
            from: time["startTime"]
        };
        this.setState(workingDays);
    }

    onEndTimeChanged(weekday: string, time: { endTime: string }) {
        const { workingDays } = this.state;
        workingDays[weekday] = {
            ...workingDays[weekday],
            to: time["endTime"]
        };
        this.setState(workingDays);
    }

    submit(formData) {
        const schedule = this.state.workingDays;
        this.props.addData(formData, schedule);
    }

    render() {
        const fm = (id: MessageDescriptor["id"]) => this.props.intl.formatMessage({ id });
        const { handleSubmit, invalid } = this.props;

        return (
                <Layout>
                    <Portlet as="main">
                      <PortletTitle iconClassName="icon-plus">
                          <FormattedMessage id="clin_view.add.header"/>
                      </PortletTitle>

                        { deployedRussia() === true
                        ? ( <div className="portlet-body form">
                            <form onSubmit={handleSubmit(this.submit)}>
                                <div className="alert alert-danger display-hide">
                                    <button className="close" data-close="alert"/>
                                    <span id="error-msg"/>
                                </div>
                                <div className="form-body">
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="title">
                                            <FormattedMessage id="clin_view.name.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="title"
                                            name="title"
                                            placeholder={fm('clin_view.name.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="email">
                                            <FormattedMessage id="clin_view.email.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="email"
                                            name="email"
                                            placeholder={fm('clin_view.email.placeholder')}
                                            validate={[validEmail]}
                                            message="clin_view.error.email"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="address_line_1">
                                            <FormattedMessage id="clin_view.address.line.1"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            id="address_line_1"
                                            name="address_line_1"
                                            placeholder={fm('clin_view.address.line.1.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="address_line_2">
                                            <FormattedMessage id="clin_view.address.line.2"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            id="address_line_2"
                                            name="address_line_2"
                                            placeholder={fm('clin_view.address.line.2.placeholder')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="address_line_3">
                                            <FormattedMessage id="clin_view.address.line.3"/>
                                            <FormattedMessage id="clin_view.address.not.more.then.35"/>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            maxLength={35}
                                            id="address_line_3"
                                            name="address_line_3"
                                            placeholder={fm('clin_view.address.line.3.placeholder')}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="zip_code">
                                            <FormattedMessage id="clin_view.zip.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="zip_code"
                                            name="zip_code"
                                            placeholder={fm('clin_view.zip.placeholder')}
                                            validate={[validateZIPCode]}
                                            message="clin_view.error.index"
                                        />
                                    </div>
                                    <h4 style={{marginTop: 32, fontWeight: 600}}>
                                        <FormattedMessage id="clin_view.administrative.contacts"/>
                                    </h4>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="phone">
                                            <FormattedMessage id="clin_view.phone.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="phone"
                                            name="phone"
                                            placeholder={fm('clin_view.phone.placeholder')}
                                            validate={[isPhoneNumber]}
                                            message="clin_view.error.phone"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="contact">
                                            <FormattedMessage id="clin_view.contacts.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="textarea"
                                            component={ClinicInput}
                                            className="form-control"
                                            rows={5}
                                            id="contact"
                                            name="contact"
                                            placeholder={fm('clin_view.contacts.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                    <h4 style={{marginTop: 32, fontWeight: 600}}>
                                        <FormattedMessage id="clin_view.delivery.contacts"/>
                                    </h4>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="logistic_phone">
                                            <FormattedMessage id="clin_view.logistic_phone.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="logistic_phone"
                                            name="logistic_phone"
                                            placeholder={fm('clin_view.logistic_phone.placeholder')}
                                            validate={[isLogisticPhoneNumber]}
                                            message="clin_view.error.phone"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label className="control-label" htmlFor="logistic_contact">
                                            <FormattedMessage id="clin_view.logistic_contact.placeholder"/>
                                            <span className="required" aria-required="true">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            component={ClinicInput}
                                            className="form-control"
                                            id="logistic_contact"
                                            name="logistic_contact"
                                            placeholder={fm('clin_view.logistic_contact.placeholder')}
                                            validate={[required]}
                                        />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="control-label">
                                        <FormattedMessage id="clin_view.schedule.schedule"/>
                                        <span className="required" aria-required="true">*</span>
                                    </label>
                                </div>
                                    <Schedule
                                        workingDays={this.state.workingDays}
                                        onWorkingDayStatusChanged={this.onWorkingDayStatusChanged}
                                        onStartTimeChanged={this.onStartTimeChanged}
                                        onEndTimeChanged={this.onEndTimeChanged}
                                    />
                                <div className="form-actions">
                                    <Button type="submit" disabled={invalid}>
                                        <FormattedMessage id="clin_view.add.button"/>
                                    </Button>
                                </div>
                            </form>
                        </div> )
                          : <Redirect to="/pages/doctor/clinics"/> }
                    </Portlet>
                </Layout>
        )
    }
};

ClinicNew = reduxForm({ form : 'clinic_new' })(ClinicNew);

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(ClinicNew));
